import {forwardRef, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Form from 'react-bootstrap/Form'
import RequiredFieldLabel from 'components/RequiredFieldLabel'
import {Formik, Form as FormikForm} from 'formik'
import {translate} from 'i18n'
import useAuthentication from 'hooks/useAuthentication'
import {licenceValidation} from './validations/licenceValidationSchema'
import DatePicker from 'react-datepicker'
import AsyncSelect from 'react-select/async'
import companyService from 'services/company/companyService'
import 'react-datepicker/dist/react-datepicker.css'
import {Button} from 'react-bootstrap'
import {
  addLicense,
  fetchServiceType,
  getAllLicenses,
  getLicense,
} from 'reduxStore/actions/licenseActions'
import {selectServiceType} from 'reduxStore/selectors/licenseSelectors'

interface GlobalLicenceFormProps {
  createAnother?: boolean
  handleClose: () => void
}

const initialFormState = {
  purchasingCompanyId: null,
  serviceId: null,
  category: null,
  xeroInvoiceNumber: null,
  licenceDuration: null,
  licenceStartDate: null,
  pricePerLicense: null,
  status: null,
  quantity: null,
  issuedTo: [],
}

const statuses = [
  {
    statusName: 'Pending payment',
    statusId: 1,
  },
  {
    statusName: 'Payment overdue',
    statusId: 2,
  },
  {
    statusName: 'Paid',
    statusId: 3,
  },
  {
    statusName: 'Cancelled',
    statusId: 4,
  },
]

const categories = ['Single', 'Bulk']

const CustomDateInput = forwardRef(({name, value, onClick, onBlur, isInvalid}: any, ref: any) => (
  <Form.Control
    ref={ref}
    type="text"
    name={name}
    value={value}
    onClick={onClick}
    onBlur={onBlur}
    isInvalid={isInvalid}
  />
))

const GlobalLicenceForm = (props: GlobalLicenceFormProps) => {
  const [startDate, setStartDate]: any = useState()
  const {handleClose} = props
  const [options, setOptions] = useState([])
  // const [selectedOptions, setSelectedOptions] = useState(storedSelectedOptions)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [createAnother, setCreateAnother] = useState(false)
  const [companyId, setCompanyId]: any = useState(null)
  const {token} = useAuthentication()
  const dispatch = useDispatch()
  // const {isLoading} = useSelector((state: any) => state.licence)
  const serviceTypes = useSelector(selectServiceType)

  useEffect(() => {
    if (token) {
      dispatch(fetchServiceType(token))
    }
  }, [dispatch, token])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    const fetchOptionsFromAPI = async (term: string) => {
      try {
        const response = await companyService.searchCompany(token, term)
        const newOptions = response.map((company: any) => ({
          value: String(company.companyId),
          label: company.companyName,
        }))
        setOptions(newOptions)
      } catch (error) {
        console.error('Error fetching options:', error)
      }
    }

    if (searchTerm.trim() !== '') {
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        fetchOptionsFromAPI(searchTerm)
      }, 500)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [searchTerm, token])

  const promiseOptions = (inputValue: string) => {
    setSearchTerm(inputValue)
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(options)
      }, 1000)
    })
  }

  const onHandleChange = (selectedOptions: any, setFieldValue: any) => {
    if (selectedOptions?.value) {
      setFieldValue('purchasingCompanyId', selectedOptions.value)
      setCompanyId(+selectedOptions.value)
    }
  }

  const onRelatedCompaniesChange = (selectedOptions: any, setFieldValue: any) => {
    if (selectedOptions) {
      const selectedCompanyIds = selectedOptions.map((option: any) => +option.value)
      setFieldValue('issuedTo', selectedCompanyIds)
    }
  }

  const handleCheckboxChange = () => {
    setCreateAnother((prevCreateAnother) => !prevCreateAnother)
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialFormState}
      validationSchema={licenceValidation}
      onSubmit={async (values: any, actions: any) => {
        await dispatch(addLicense({token, data: values}))
        await dispatch(getAllLicenses({token}))
        handleClose()
        actions.resetForm()
      }}
    >
      {({handleBlur, handleChange, values, touched, errors, setFieldValue}: any) => {
        const updatedLinkedFields = (value: string) => {
          const serviceType = serviceTypes?.find(
            (service: any) => Number(service.id) === Number(value)
          )

          setFieldValue('pricePerLicense', serviceType?.defaultPrice ?? '')
          setFieldValue('licenceDuration', serviceType?.defaultDuration ?? 12)
          const date = new Date()
          setStartDate(date)
          setFieldValue('licenceStartDate', date.toISOString())
          setFieldValue('quantity', 1)
        }

        return (
          <FormikForm>
            <Form.Group className="mb-3 validation-field" controlId="service">
              <Form.Label>
                <RequiredFieldLabel label={translate('forms.licence.service')} />
              </Form.Label>
              <Form.Control
                as="select"
                name="serviceId"
                onChange={(e: any) => {
                  updatedLinkedFields(e.target.value)
                  handleChange(e)
                }}
                onBlur={handleBlur}
                isInvalid={touched?.serviceId && errors?.serviceId}
                value={values?.serviceId}
              >
                <option>{translate('global.select')}</option>
                {serviceTypes.map((service: any) => (
                  <option key={service.id} value={service.id}>
                    {service.serviceName}
                  </option>
                ))}
              </Form.Control>
              <div className="invalid-feedback error">{errors?.serviceId}</div>
            </Form.Group>

            <Form.Group className="mb-3 validation-field" controlId="company">
              <Form.Label>
                <RequiredFieldLabel label={translate('forms.licence.company')} />
              </Form.Label>
              <AsyncSelect
                name="purchasingCompanyId"
                cacheOptions
                loadOptions={promiseOptions}
                onChange={(selectedOptions) => onHandleChange(selectedOptions, setFieldValue)}
                onBlur={handleBlur}
                placeholder={'Search company...'}
              />
              <div className="invalid-feedback error">
                {touched['react-select-2-input'] &&
                  !values?.purchasingCompanyId &&
                  'Company is required'}
              </div>
            </Form.Group>

            <Form.Group className="mb-3 validation-field" controlId="category">
              <Form.Label>
                <RequiredFieldLabel label={translate('forms.licence.category')} />
              </Form.Label>
              <Form.Control
                as="select"
                name="category"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched?.category && errors?.category}
                value={values?.category}
              >
                <option>{translate('global.select')}</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Control>
              <div className="invalid-feedback error">{errors?.category}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="invoiceNumber">
              <Form.Label>
                <RequiredFieldLabel label={translate('forms.licence.invoiceNumber')} />
              </Form.Label>
              <Form.Control
                type="text"
                name="xeroInvoiceNumber"
                value={values.xeroInvoiceNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched?.xeroInvoiceNumber && errors?.xeroInvoiceNumber}
              />
              <div className="invalid-feedback error">{errors?.xeroInvoiceNumber}</div>
            </Form.Group>

            <Form.Group className="mb-3 address-field" controlId="status">
              <Form.Label>
                <RequiredFieldLabel label={translate('forms.licence.status')} />
              </Form.Label>
              <Form.Control
                as="select"
                name="status"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched?.status && errors?.status}
                value={values?.status}
              >
                <option>{translate('global.select')}</option>
                {statuses.map((status) => (
                  <option key={status.statusId} value={status.statusId}>
                    {status.statusName}
                  </option>
                ))}
              </Form.Control>
              <div className="invalid-feedback error">{errors?.status}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="licenceDuration">
              <Form.Label>
                <RequiredFieldLabel label={translate('forms.licence.duration') + ' (months)'} />
              </Form.Label>
              <Form.Control
                type="number"
                min={1}
                name="licenceDuration"
                value={values.licenceDuration}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched?.licenceDuration && errors?.licenceDuration}
              />
              <div className="invalid-feedback error">{errors?.licenceDuration}</div>
            </Form.Group>

            <Form.Group className="mb-3 validation-field" controlId="startDate">
              <Form.Label>
                <RequiredFieldLabel label={translate('forms.licence.startDate')} />
              </Form.Label>
              <div>
                <DatePicker
                  name="licenceStartDate"
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date)
                    setFieldValue('licenceStartDate', date.toISOString())
                  }}
                  onBlur={handleBlur}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <CustomDateInput
                      isInvalid={touched?.licenceStartDate && errors?.licenceStartDate}
                    />
                  }
                />
              </div>
              <div className="invalid-feedback error">{errors?.licenceStartDate}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="pricePerLicence">
              <Form.Label>
                <RequiredFieldLabel label={translate('forms.licence.pricePerLicence')} />
              </Form.Label>
              <Form.Control
                type="text"
                name="pricePerLicense"
                value={values?.pricePerLicense}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched?.pricePerLicense && errors?.pricePerLicense}
              />
              <div className="invalid-feedback error">{errors?.pricePerLicense}</div>
            </Form.Group>

            {values?.category === 'Bulk' && (
              <>
                <Form.Group className="mb-3" controlId="quantity">
                  <Form.Label>
                    <RequiredFieldLabel label={translate('forms.licence.quantity')} />
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min={1}
                    name="quantity"
                    value={values.quantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched?.quantity && errors?.quantity}
                  />
                  <div className="invalid-feedback error">{errors?.quantity}</div>
                </Form.Group>
                <Form.Group className="mb-3 validation-field" controlId="parties">
                  <Form.Label>
                    <RequiredFieldLabel label={translate('forms.licence.parties')} />
                  </Form.Label>
                  <AsyncSelect
                    name="issuedTo"
                    isMulti
                    cacheOptions
                    loadOptions={promiseOptions}
                    onChange={(selectedOptions) =>
                      onRelatedCompaniesChange(selectedOptions, setFieldValue)
                    }
                    onBlur={handleBlur}
                    placeholder={'Search Parties...'}
                    isDisabled={
                      values?.issuedTo?.length >= values?.quantity || values?.quantity < 1
                    }
                  />
                  <div className="invalid-feedback error">
                    {touched['react-select-2-input'] &&
                      !values?.issuedTo?.length &&
                      'Parties is required'}
                  </div>
                  <div className="warning-feedback warning">
                    {values?.quantity &&
                      values.issuedTo?.length >= values?.quantity &&
                      `Associated parties cannot surpass quantity(${values.quantity}) of licences purchased`}
                  </div>
                  <div className="warning-feedback warning">
                    {!values?.quantity && `Please add quantity`}
                  </div>
                </Form.Group>
              </>
            )}

            <div className="d-flex justify-content-between gap-2 mt-5">
              <Form.Group controlId="createAnotherLicense">
                <Form.Check
                  type="checkbox"
                  label={translate('global.createAnotherLicense')}
                  checked={createAnother}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>

              <div className="d-flex gap-2">
                <Button type="submit" variant="primary" disabled={Object.keys(errors).length > 0}>
                  {translate('global.create')}
                </Button>
                <Button variant="outline-primary" onClick={handleClose}>
                  {translate('global.cancel')}
                </Button>
              </div>
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default GlobalLicenceForm
