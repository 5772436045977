import {requestBase as request, handleAxiosError} from '../requestBase'

const addressSearch: any = async (token: string, address: string) => {
  try {
    const result = await request(token).get(`Services/Address-Search/${address}`)
    return result.data?.data?.suggestions
  } catch (err) {
    handleAxiosError(err)
  }
}

const addressGet: any = async (token: string, addressId: string) => {
  try {
    const result = await request(token).get(`Services/Address-Get/${addressId}`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const serviceService = {
  addressSearch,
  addressGet,
}

export default serviceService
