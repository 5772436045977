import {createSlice} from '@reduxjs/toolkit'
import {
  getContact,
  getContacts,
  addContact,
  editContact,
  searchContacts,
} from 'reduxStore/actions/contactActions'

const initialState = {
  contact: {},
  contacts: [],
  searchResults: [],
  isLoading: false,
  isError: false,
}

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContact.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.contact = action.payload
        state.isLoading = false
      })
      .addCase(getContact.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getContacts.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.contacts = action.payload
        state.isLoading = false
      })
      .addCase(getContacts.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(addContact.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addContact.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addContact.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(editContact.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(editContact.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(editContact.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(searchContacts.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(searchContacts.fulfilled, (state, action) => {
        state.searchResults = action.payload
        state.isLoading = false
      })
      .addCase(searchContacts.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
  },
})

export {getContact, getContacts, addContact, editContact, searchContacts}
export default contactSlice.reducer
