import React from 'react'
import Pagination from 'react-bootstrap/Pagination'
import Stack from 'react-bootstrap/Stack'
import {StyledPagination} from '../styles/styled'
import {translate} from 'i18n'

interface CustomPaginationProps {
  currentPage: number
  onChangePage: (page: number) => void
  rowCount: number
  rowsPerPage: number
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  currentPage,
  onChangePage,
  rowCount,
  rowsPerPage,
}) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage)

  const pageNumbers = []
  for (let i = 1; i <= totalPages; i++) {
    if (i === 1 || i === totalPages || (i >= currentPage - 2 && i <= currentPage + 2)) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onChangePage(i)}>
          {i}
        </Pagination.Item>
      )
    } else if ((i === currentPage - 3 && i > 2) || (i === currentPage + 3 && i < totalPages - 1)) {
      pageNumbers.push(<Pagination.Ellipsis key={i} disabled />)
    }
  }

  const startEntry = (currentPage - 1) * rowsPerPage + 1
  const endEntry = Math.min(currentPage * rowsPerPage, rowCount)
  const previousDisabled = currentPage === 1
  const nextDisabled = currentPage === totalPages

  const handleNextPage = () => {
    if (!nextDisabled) {
      onChangePage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (!previousDisabled) {
      onChangePage(currentPage - 1)
    }
  }

  const displayText: any = rowCount
    ? translate('pagination.displayText', {startEntry, endEntry, rowCount})
    : ''

  return (
    <Stack className="mt-3" direction="horizontal" gap={3}>
      <div className="p-2">{displayText}</div>
      <div className="p-2 ms-auto">
        <StyledPagination>
          <Pagination.First disabled={previousDisabled} onClick={() => onChangePage(1)} />
          <Pagination.Prev disabled={previousDisabled} onClick={handlePreviousPage} />
          {pageNumbers}
          <Pagination.Next disabled={nextDisabled} onClick={handleNextPage} />
          <Pagination.Last disabled={nextDisabled} onClick={() => onChangePage(totalPages)} />
        </StyledPagination>
      </div>
    </Stack>
  )
}

export default CustomPagination
