import {createAsyncThunk} from '@reduxjs/toolkit'
import authService from 'services/auth/authService'

export const acquireTokenByCode: any = createAsyncThunk(
  'auth/acquireTokenByCode',
  async (data: string) => {
    const result = await authService.acquireTokenByCode(data)
    return result
  }
)

export const isAuthenticated: any = createAsyncThunk(
  'auth/isAuthenticated',
  async (token: string) => {
    const result = await authService.isAuthenticated(token)
    return result
  }
)

export const getUsers: any = createAsyncThunk('auth/getUsers', async (token: string) => {
  const result = await authService.getUsers(token)
  return result
})

export const getUser: any = createAsyncThunk(
  'auth/getUser',
  async ({token, userId}: {token: string; userId: string}) => {
    const result = await authService.getUser(token, userId)
    return result
  }
)

export const addUser: any = createAsyncThunk(
  'auth/addUser',
  async ({token, data}: {token: string; data: any}) => {
    const result = await authService.addUser(token, data)
    return result
  }
)

export const updatedUser: any = createAsyncThunk(
  'auth/updatedUser',
  async ({token, userId, data}: {token: string; userId: string; data: any}) => {
    const result = await authService.updatedUser(token, userId, data)
    return result
  }
)

export const getRoles: any = createAsyncThunk('auth/getRoles', async (token: string) => {
  const result = await authService.getRoles(token)
  return result
})

export const getPermissions: any = createAsyncThunk(
  'auth/getPermissions',
  async (token: string) => {
    const result = await authService.getPermissions(token)
    return result
  }
)
