import Row from 'react-bootstrap/Row'
import Router from 'router'
import Navigation from './navigation'
import {StyledAppContainer, StyledAppInnerContainer, StyledAppRow} from 'styles/styled'
import PageLoader from 'components/PageLoader'
import useAuthentication from 'hooks/useAuthentication'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoggedOut from 'pages/shared/LoggedOut'

function App() {
  const {token, isLoggedOut} = useAuthentication()

  if (!token) {
    if (isLoggedOut) {
      return <LoggedOut />
    } else {
      return <PageLoader />
    }
  }

  return (
    <>
      <StyledAppContainer>
        <StyledAppInnerContainer>
          <StyledAppRow>
            <Navigation data-testid="navigation" />
          </StyledAppRow>
          <Row>
            <Router />
          </Row>
        </StyledAppInnerContainer>
      </StyledAppContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </>
  )
}

export default App
