import React, {createContext, useState} from 'react'
import environment from 'config/environment'

export type UserObject = {
  id: string
  displayName: string
  givenName: string
  surname: string
  userPrincipalName: string
  error: string
  validUser: boolean
  jobTitle: string
  token: string
}

export type GlobalConfig = {
  msalAuthority: string | undefined
  msalAppId: string | undefined
  msalRedirectUrl: string
  setUser: (userObject: UserObject) => void
  getUser: () => UserObject | any
  clearUser: () => void
  isLoggedOut?: boolean
}

const originalUrl = window.location.href
const originUrl = window.location.origin

export const localConfig: GlobalConfig = {
  msalAppId: process.env.REACT_APP_MSAL_APPID,
  msalAuthority: process.env.REACT_APP_MSAL_AUTHORITY,
  msalRedirectUrl: originUrl ?? environment.msalRedirectUri,
  setUser: () => {},
  getUser: () => {},
  clearUser: () => {},
}

export const loginUrl = `${
  localConfig.msalAuthority
}/oauth2/authorize/?response_type=code&client_id=${
  localConfig.msalAppId
}&resource=https%3a%2f%2fgraph.microsoft.com&redirect_uri=${encodeURIComponent(
  localConfig.msalRedirectUrl
)}&scope=user.read&state=${encodeURIComponent(originalUrl)}`

export const Config: React.Context<GlobalConfig> = createContext({} as GlobalConfig)

export const ConfigProvider: React.FC<any> = ({children}) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false)
  const config =
    process.env.REACT_APP_LOCAL === 'true' ? localConfig : (window as any).ReactAppConfig

  const setUser = (userObject: UserObject) => {
    localStorage.setItem('user', JSON.stringify(userObject))
    setIsLoggedOut(false)
  }

  const getUser = () => {
    const userObject: any = localStorage.getItem('user')
    return JSON.parse(userObject)
  }

  const clearUser = () => {
    setIsLoggedOut(true)
    localStorage.removeItem('user')
  }

  return (
    <Config.Provider
      value={{
        ...config,
        isLoggedOut,
        getUser,
        setUser,
        clearUser,
      }}
    >
      {children}
    </Config.Provider>
  )
}

export const useConfig = () => React.useContext(Config)
