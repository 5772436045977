import {object, string, number, array} from 'yup'

export const licenceValidation = object().shape({
  purchasingCompanyId: string().required('Purchasing Company Id is required'),
  serviceId: string().required('Service is required'),
  category: string().required('Category is required'),
  xeroInvoiceNumber: string().required('Invoice Number is required'),
  licenceDuration: number()
    .required('License Duration is required')
    .test('no-negative-number', 'Duration is invalid', (value: any) => {
      return value >= 0
    }),
  licenceStartDate: string().required('Start Date is required'),
  pricePerLicense: string().required('Price Per License is required'),
  status: string().required('Invoice Status is required'),
  quantity: number().test('no-negative-number', 'Quantity is invalid', (value: any) => {
    return value >= 0
  }),
  issuedTo: array().of(string()).required('Parties is required'),
})
