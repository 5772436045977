import {useEffect, useState, ChangeEvent} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {StyledPageTitle, StyledSearchInput} from 'styles/styled'
import {translate} from 'i18n'
import {ICompany} from 'types/company'
import {licenseColumnsDef} from 'grids/licenseColumnsDef'
import DataTable from 'components/DataTable'
import ReusableModal from 'components/ReusableModal'
import useAuthentication from 'hooks/useAuthentication'
import {useDispatch, useSelector} from 'react-redux'
import {getContacts} from 'reduxStore/actions/contactActions'
import {getContactsSelector} from 'reduxStore/selectors/contactSelectors'
import GlobalLicenceForm from 'forms/GlobalLicenceForm'
import {getAllLicenses} from 'reduxStore/actions/licenseActions'
import {selectAllLicenseData} from 'reduxStore/selectors/licenseSelectors'

export default function Licences() {
  const [searchText, setSearchText] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState<any>({})
  const {token} = useAuthentication()

  const dispatch = useDispatch()
  const licensesData = useSelector(selectAllLicenseData)

  useEffect(() => {
    if (token) {
      dispatch(getAllLicenses({token}))
    }
  }, [dispatch, token])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const filteredData: any = licensesData?.filter((item: ICompany) =>
    Object.values(item).some(
      (value) => value && value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  )

  const handleClose = () => {
    setIsModalOpen(false)
    setIsEdit(false)
  }

  // const handleRowClicked = (data: any) => {
  //   setIsEdit(true)
  //   setEditData(data)
  //   setIsModalOpen(true)
  // }

  return (
    <>
      <Container className="p-5">
        <Row className="align-items-center justify-content-between">
          <Col md={6}>
            <StyledPageTitle className="text-left">Licenses</StyledPageTitle>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="primary"
              onClick={() => {
                setEditData(false)
                setIsModalOpen(true)
              }}
            >
              Add License
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between mt-3">
          <Col md={6}>
            <Col md={5}>
              <Form>
                <StyledSearchInput
                  className="mb-2"
                  placeholder={'Search a license'}
                  onChange={handleSearch}
                />
              </Form>
            </Col>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DataTable
              data={filteredData}
              columns={licenseColumnsDef}
              // onRowClicked={handleRowClicked}
              responsive
            />
          </Col>
        </Row>
      </Container>

      <ReusableModal
        showModal={isModalOpen}
        handleClose={handleClose}
        modalTitle={'Add New License'}
        showFooter={false}
        modalBody={<GlobalLicenceForm handleClose={handleClose} />}
      />
    </>
  )
}
