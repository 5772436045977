export const projectData = [
  {
    id: 1,
    title: 'Lead Name',
    value: 2323.22,
    status: 'pending',
    ownerId: 6,
    ownerDisplayName: 'Veli Sithole',
    dateCreated: '2023-09-27T07:20:17.683Z',
    lastUpdated: '2023-09-27T07:20:17.683Z',
    dueAt: '2023-09-27T07:20:17.683Z',
    tags: 'Product Tag',
    kanbanType: 'opportunity',
    company: 'Momentum Health',
    listPosition: 1,
    priority: 'Low',
  },
  {
    id: 2,
    title: 'Opportunity Name',
    value: 2323.22,
    status: 'booked',
    ownerId: 4,
    ownerDisplayName: 'John Kani',
    dateCreated: '2023-09-27T07:20:17.683Z',
    lastUpdated: '2023-09-27T07:20:17.683Z',
    dueAt: '2023-09-27T07:20:17.683Z',
    tags: 'Product Tag',
    kanbanType: 'opportunity',
    company: 'Prime Care',
    listPosition: 2,
    priority: 'Medium',
  },
  {
    id: 3,
    title: 'Opportunity Name',
    value: 2323.22,
    status: 'grace',
    ownerId: 1,
    ownerDisplayName: 'Darryn Hart',
    dateCreated: '2023-09-27T07:20:17.683Z',
    lastUpdated: '2023-09-27T07:20:17.683Z',
    dueAt: '2023-09-27T07:20:17.683Z',
    tags: 'Product Tag',
    kanbanType: 'opportunity',
    company: 'Medi Shield',
    listPosition: 3,
    priority: 'Low',
  },
  {
    id: 4,
    title: 'Lead Name',
    value: 2323.22,
    status: 'grace',
    ownerId: 2,
    ownerDisplayName: 'Kara Mkhize',
    dateCreated: '2023-09-27T07:20:17.683Z',
    lastUpdated: '2023-09-27T07:20:17.683Z',
    dueAt: '2023-09-27T07:20:17.683Z',
    tags: 'Product Tag',
    kanbanType: 'opportunity',
    company: 'Thoughtsphere',
    listPosition: 4,
    priority: 'High',
  },
]
