import {translate} from 'i18n'

const sharedColumnProps = {
  minWidth: '100px',
}

export const licenseColumnsDef: any[] = [
  {
    name: 'Company Name',
    selector: (row: any) => row.purchasingCompany,
    sortable: true,
    ...sharedColumnProps,
  },
  {
    name: 'Service',
    selector: (row: any) => row.serviceName,
    ...sharedColumnProps,
  },
  {
    name: 'Invoice Number',
    selector: (row: any) => row.xeroInvoiceNumber,
    maxWidth: '150px',
  },
  {
    name: 'Start Date',
    selector: (row: any) => row.licenseStartDate,
    maxWidth: '150px',
  },
  {
    name: 'End Date',
    selector: (row: any) => row.licenceEndDate,
    maxWidth: '150px',
  },
  {
    name: 'Value',
    selector: (row: any) => `£${row.pricePerLicence}`,
    maxWidth: '80px',
  },
  {
    name: 'Category',
    selector: (row: any) => row.type,
    maxWidth: '50px',
  },
  {
    name: 'Status',
    selector: (row: any) => row.status,
    maxWidth: '50px',
  },
]
