import {createAsyncThunk} from '@reduxjs/toolkit'
import companyService from 'services/company/companyService'

export const getCompany: any = createAsyncThunk(
  'company/getCompany',
  async ({token, companyId}: {token: string; companyId: string}) => {
    const result = await companyService.getCompany(token, companyId)
    return result
  }
)

export const getCompanies = createAsyncThunk('company/getCompanies', async (token: string) => {
  const result = await companyService.getCompanies(token)
  return result
})

export const getCompanySummaries: any = createAsyncThunk(
  'company/getCompanySummaries',
  async (token: string) => {
    const result = await companyService.getCompanySummaries(token)
    return result
  }
)

export const editCompany: any = createAsyncThunk(
  'company/editCompany',
  async ({token, companyId, data}: {token: string; companyId: string; data: any}) => {
    const result = await companyService.editCompany(token, companyId, data)
    return result
  }
)

export const addCompany: any = createAsyncThunk(
  'company/addCompany',
  async ({token, data}: {token: string; data: any}) => {
    const result = await companyService.addCompany(token, data)
    return result
  }
)

export const getCompanyTypes: any = createAsyncThunk(
  'company/getCompanyTypes',
  async (token: string) => {
    const result = await companyService.getCompanyTypes(token)
    return result
  }
)

export const getOpportunities = createAsyncThunk(
  'company/getOpportunities',
  async (token: string) => {
    const result = await companyService.getOpportunities(token)
    return result
  }
)

export const getContracts = createAsyncThunk('company/getContracts', async (token: string) => {
  const result = await companyService.getContracts(token)
  return result
})

export const getRelatedContacts: any = createAsyncThunk(
  'company/getRelatedContacts',
  async ({token, companyId, type}: {token: string; companyId: string; type: string}) => {
    const result = await companyService.getRelatedContacts(token, companyId, type)
    return result
  }
)

export const getRelatedCompanies: any = createAsyncThunk(
  'company/getRelatedCompanies',
  async ({token, companyId}: {token: string; companyId: string; type: string}) => {
    const result = await companyService.getRelatedCompanies(token, companyId)
    return result
  }
)

export const getXeroRef: any = createAsyncThunk('company/getXeroRef', async (token: string) => {
  const result = await companyService.getXeroRef(token)
  return result
})

export const addLinkedCompany: any = createAsyncThunk(
  'company/addLinkedCompany',
  async ({token, data, companyId}: {token: string; data: any; companyId: string}) => {
    const result = await companyService.addLinkedCompany(token, data, companyId)
    return result
  }
)

export const deleteLinkedCompany: any = createAsyncThunk(
  'company/deleteLinkedCompany',
  async ({
    token,
    parentCompanyId,
    childCompanyId,
  }: {
    token: string
    parentCompanyId: string
    childCompanyId: string
  }) => {
    const result = await companyService.deleteLinkedCompany(token, parentCompanyId, childCompanyId)
    return result
  }
)

export const searchCompany: any = createAsyncThunk(
  'company/searchCompany',
  async ({token, term}: {token: string; term: string}) => {
    const result = await companyService.searchCompany(token, term)
    return result
  }
)
