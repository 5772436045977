const en = {
  global: {
    filter: 'Filter',
    sort: 'Sort',
    dateCreated: 'Date Created',
    email: 'Email',
    password: 'Password',
    cancel: 'Cancel',
    next: 'Next',
    back: 'Back',
    edit: 'Edit',
    view: 'View',
    delete: 'Delete',
    add: 'Add',
    save: 'Save',
    select: 'Select',
    search: 'Search...',
    saveChanges: 'Save Changes',
    update: 'Update',
    create: 'Create',
    actions: 'Actions',
    find: 'Find',
    createAddAnother: 'Create and add another',
    createAnotherContact: 'Create another contact',
    createAnotherLicense: 'Create another license',
    markAsPrimary: 'Mark as Primary',
    emailNumberWarning: 'Please fill in either phone number or email address',
  },
  grid: {
    company: {
      companyName: 'Company Name',
      companyType: 'Company Type',
      parentCompany: 'Parent Company',
      sites: 'Sites',
      leads: 'Leads',
      opportunities: 'Opportunities',
      accounts: 'Accounts',
      practiceManager: 'Practice Manager',
      phoneNumber: 'Phone Number',
      accountOwner: 'Account Owner',
      leadName: 'Lead Name',
      dueDate: 'Due Date',
      stage: 'Stage',
      opportunityOwner: 'Opportunity Owner',
      contractName: 'Contract Name',
      startDate: 'Start Date',
      endDate: 'End Date',
      renewalDate: 'Renewal Date',
      primaryContact: 'Primary Contact',
    },
    contact: {
      contactName: 'Contact Name',
      contactType: 'Contact Type',
      company: 'Company',
      emailAddress: 'Email Address',
      phoneNumber: 'Phone Number',
      role: 'Role',
    },
    admin: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      alias: 'Alias',
      userRole: 'User Role',
      lastActive: 'Last Active',
      displayName: 'Display Name',
      licenceName: 'License Name',
      serviceName: 'Service Name',
      defaultPrice: 'Default Price',
      defaultDuration: 'Default Duration',
    },
  },
  tabs: {
    company: {
      companyDetails: 'Company Details',
      contacts: 'Contacts',
      sites: 'Sites',
      accountDetails: 'Account Details',
    },
  },
  forms: {
    companyType: 'Company Type',
    select: 'Select',
    companyName: 'Company Name',
    email: 'Email Address',
    phone: 'Phone Number',
    address: 'Address',
    postcode: 'Postcode',
    sites: {
      practiceManager: 'Practice Manager',
      practiceManagers: 'Practice Managers',
      address: 'Address',
      siteType: 'Site Type',
      siteName: 'Site Name',
      siteAddress: 'Site Address',
      jayexId: 'Jayex ID',
      portalUrl: 'Portal URL',
      addPracticeManager: 'Add Practice Manager',
    },
    details: {
      practiceNumber: 'Practice Number',
      linkedCompanies: 'Linked Companies',
      linkCompany: '+ Link Company',
      businessInformation: 'Business Information',
      addPhoneNumber: '+ Add Phone Number',
      website: 'Website',
      businessRegistrationNumber: 'Business Registration Number',
      linkCompanyTitle: 'Link Company',
      createLink: 'Create Link',
      updateLink: 'Update Link',
      xeroRefNumber: 'Xero Reference Number',
      xeroLink: 'Xero Link',
      oldCRMLink: 'Old CRM Link',
    },
    contact: {
      addContact: '+ Add Contact',
      editContact: 'Edit Contact',
      addContactPersons: 'Add Contact Persons',
      addRelatedContactPerson: 'Add Related Contact Person',
    },
    account: {
      jayexAccountManager: 'Jayex Account Manager',
      jayexRole: 'Jayex Role',
      existingClient: 'Existing Client?',
      pastCrmId: 'Old CRM ID',
    },
    licence: {
      service: 'Service',
      category: 'Category',
      invoiceNumber: 'Invoice Number',
      duration: 'License Duration',
      startDate: 'Start Date',
      pricePerLicence: 'Price Per License',
      status: 'Invoice Status',
      parties: 'Parties',
      quantity: 'Quantity',
      company: 'Company',
    },
  },
  admin: {
    title: 'Admin Panel',
    addUser: 'Add a user',
    downloadCSV: 'Download CSV',
    search: 'Search User',
    searchService: 'Search a service',
    users: 'Users',
    licences: 'Licences',
    services: 'Services',
    kanban: 'Kanban',
    addServiceType: 'Add a service',
    addKanbanBoard: 'Add a board',
    boardName: 'Board Name',
    columnName: 'Column Name',
    type: 'Type',
  },
  dashboard: {
    title: 'Dashboard',
  },
  companies: {
    title: 'Companies',
    create: 'Create Company',
    search: 'Search Companies',
    editCompany: 'Edit Company',
    createCompany: 'Create Company',
    companyAliases: 'Company Aliases',
    companyTags: 'Company Tags',
  },
  contacts: {
    title: 'Contacts',
    create: 'Create Contact',
    add: 'Add Contact',
    edit: 'Edit Contact',
    search: 'Search Contacts',
    createEditContact: '%{text} Contact',
  },
  sites: {
    title: 'Sites',
    addAdditionalSite: '+ Add Additional Site',
    modalTitle: '%{type} Site',
  },
  menu: {
    dashboards: 'Dashboards',
    admin: 'Admin',
    companies: 'Companies',
    contacts: 'Contacts',
    sales: 'Sales',
    installs: 'Installs',
    opportunities: 'Opportunities',
    products: 'Products',
    tasks: 'Tasks',
    accounting: 'Accounting',
    overview: 'Overview',
    notes: 'Notes',
    emails: 'Emails',
    calls: 'Calls',
    meetings: 'Meetings',
    solutions: 'Solutions',
    licenses: 'Licenses',
  },
  pagination: {
    displayText: '%{startEntry} - %{endEntry} of %{rowCount} entries',
  },
}

export default en
