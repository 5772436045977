import {
  requestBase as request,
  IGetAsyncFunction,
  IPostAsyncFunction,
  handleAxiosError,
} from '../requestBase'
import {ICompany, ICompanyEdit, ICompanyRelationship} from 'types/company'

const getCompany = async (token: string, companyId: string | undefined, signal?: AbortSignal) => {
  const controller = new AbortController()
  const defaultSignal = controller.signal

  try {
    const result = await request(token).get(`Companies/Company/${companyId}`, {
      signal: signal || defaultSignal,
    })
    return result.data
  } catch (err: any) {
    if (err.name === 'AbortError') {
      console.log('Request aborted:', err.message)
    } else {
      handleAxiosError(err)
    }
  } finally {
    controller.abort()
  }
}

const getCompanies: IGetAsyncFunction = async (token) => {
  try {
    const result = await request(token).get('Companies')
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getCompanySummaries: IGetAsyncFunction = async (token) => {
  try {
    const result = await request(token).get('Companies/GetCompanySummaries')
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const addCompany: IPostAsyncFunction = async (token: string, data?: ICompany) => {
  try {
    const result = await request(token).post('Wizard', data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const editCompany: any = async (token: string, companyId: string, data?: ICompanyEdit) => {
  try {
    const result = await request(token).put(`Companies/company/${companyId}`, data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getCompanyTypes: IGetAsyncFunction = async (token: string) => {
  try {
    const result = await request(token).get('CompanyType')
    return result?.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getOpportunities: IGetAsyncFunction = async (token: string) => {
  try {
    const result = await request(token).get('Opportunity')
    return result.data
  } catch (err) {
    // handleAxiosError(err)
  }
}

const getContracts: IGetAsyncFunction = async (token: string) => {
  try {
    const result = await request(token).get('Contract')
    return result.data
  } catch (err) {
    // handleAxiosError(err)
  }
}

const getRelatedContacts: IGetAsyncFunction = async (
  token: string,
  id: string | number,
  type = 'Company'
) => {
  try {
    const result = await request(token).get(`Contact?${type}=${id}`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const addLinkedCompany: IPostAsyncFunction = async (
  token: string,
  data?: ICompanyRelationship,
  companyId?: string
) => {
  try {
    const result = await request(token).post(`Companies/Link/${companyId}`, data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getXeroRef: IGetAsyncFunction = async (token: string) => {
  try {
    const result = await request(token).get(`Companies/Xero`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const deleteLinkedCompany: any = async (
  token: string,
  parentCompanyId: string,
  childCompanyId: string
) => {
  try {
    const result = await request(token).delete(
      `Companies/Link/${parentCompanyId}/${childCompanyId}`
    )
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getRelatedCompanies: IGetAsyncFunction = async (token: string, id: string | number) => {
  try {
    const result = await request(token).get(`Companies/RelatedCompanies/${id}`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const searchCompany: any = async (token: string, term: string) => {
  try {
    const result = await request(token).get(`Companies?search=${term}`)
    return result.data?.data
  } catch (err) {
    console.log('err', err)
    // handleAxiosError(err)
  }
}

const companyService = {
  getCompany,
  getCompanies,
  getCompanySummaries,
  addCompany,
  editCompany,
  getCompanyTypes,
  getOpportunities,
  getContracts,
  getRelatedContacts,
  getRelatedCompanies,
  getXeroRef,
  addLinkedCompany,
  deleteLinkedCompany,
  searchCompany,
}

export default companyService
