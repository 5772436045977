import {Route, Routes} from 'react-router-dom'
import {lazy, Suspense} from 'react'
import PageLoader from 'components/PageLoader'
import RoutePaths from 'enums/routes'

const ListCompanies = lazy(() => import(/* webpackChunkName: "ListCompanies" */ 'pages/company'))
const CompanyDetail = lazy(
  () => import(/* webpackChunkName: "CompanyDetail" */ 'pages/company/detail')
)
const CreateEditCompany = lazy(
  () => import(/* webpackChunkName: "CreateEditCompany" */ 'pages/company/add')
)

export default function CompanyRoutes() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path={RoutePaths.HOME} element={<ListCompanies />} />
        <Route path={RoutePaths.CREATE} element={<CreateEditCompany />} />
        <Route path={RoutePaths.EDIT} element={<CreateEditCompany />} />
        <Route path={RoutePaths.COMPANY_DETAILS + '*'} element={<CompanyDetail />} />
      </Routes>
    </Suspense>
  )
}
