import {ILogin, IUserEdit} from 'types/user'
import {requestBase as request, handleAxiosError} from '../requestBase'

const acquireTokenByCode: any = async (data: ILogin) => {
  try {
    const response = await request().post('Authorise', data)
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    handleAxiosError(err)
  }
}

const isAuthenticated: any = async (token: string) => {
  try {
    const response = await request(token).get('Authorise/Ping')
    if (response.status === 401) return false
    return response.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getUsers: any = async (token: string) => {
  try {
    const response = await request(token).get('Users')
    return response.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getUser: any = async (token: string, userId: string) => {
  try {
    const response = await request(token).get(`Users/${userId}`)
    return response.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const addUser: any = async (token: string, data: any) => {
  try {
    const response = await request(token).post(`Users`, data)
    return response.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const updatedUser: any = async (token: string, userId: string, data: IUserEdit) => {
  try {
    const response = await request(token).put(`Users/${userId}`, data)
    return response.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getRoles: any = async (token: string) => {
  try {
    const response = await request(token).get(`Users/roles`)
    return response.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getPermissions: any = async (token: string) => {
  try {
    const response = await request(token).get(`Users/Permissions`)
    return response.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const authService = {
  acquireTokenByCode,
  isAuthenticated,
  getUsers,
  getUser,
  addUser,
  updatedUser,
  getRoles,
  getPermissions,
}

export default authService
