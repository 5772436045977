import {createSlice} from '@reduxjs/toolkit'
import {
  fetchServiceType,
  addServiceType,
  addLicense,
  getLicense,
  getAllLicenses,
} from '../actions/licenseActions'

type IServiceType = any[]
type ILicenseData = any

interface LicenseState {
  data: ILicenseData
  licence: ILicenseData[]
  serviceType: IServiceType[] | null
  isLoading: boolean
  isError: boolean
}

const initialState: LicenseState = {
  data: {},
  licence: [],
  serviceType: [],
  isLoading: false,
  isError: false,
}

const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLicense.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getLicense.fulfilled, (state, action) => {
        state.data = action.payload
        state.isLoading = false
      })
      .addCase(getLicense.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getAllLicenses.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getAllLicenses.fulfilled, (state, action) => {
        state.licence = action.payload
        state.isLoading = false
      })
      .addCase(getAllLicenses.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(fetchServiceType.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(fetchServiceType.fulfilled, (state, action) => {
        state.serviceType = action.payload
        state.isLoading = false
      })
      .addCase(fetchServiceType.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(addServiceType.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addServiceType.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addServiceType.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(addLicense.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addLicense.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addLicense.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
  },
})

export default licenseSlice.reducer
