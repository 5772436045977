import {array, boolean, number, object, string} from 'yup'

const invalidPhoneNumber = 'Invalid Phone Number format'
const invalidEmailAddress = 'Invalid Email Address format'
const requiredPhoneNumber = 'Phone Number is required'
const requiredEmailAddress = 'Email Address is required'
const longEmailAddress = 'Email Address is too long'

export const contactValidationSchema = object().shape({
  contactFirstName: string().required('First Name is required'),
  contactLastName: string().required('Last Name is required'),
  contactRole: string().required('Role is required'),
  contactEmail: string()
    .email(invalidEmailAddress)
    .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, invalidEmailAddress)
    .test('no-consecutive-dots', invalidEmailAddress, (value: any) => !value?.includes('..'))
    .max(255, longEmailAddress),
  contactPhone: string()
    .test('valid-phone-number', invalidPhoneNumber, (value) => {
      if (!value) {
        return true
      }
      return /^\d{10,11}$/.test(value)
    })
    .test('numeric-characters-only', invalidPhoneNumber, (value) => {
      if (!value) {
        return true
      }
      return /^\d+$/.test(value)
    }),
})

export const userValidationSchema: any = object().shape({
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  role: string().required('Role is required'),
  alias: string().required('Alias is required'),
  emailAddress: string()
    .required(requiredEmailAddress)
    .email(invalidEmailAddress)
    .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, invalidEmailAddress)
    .test('no-consecutive-dots', invalidEmailAddress, (value: any) => !value?.includes('..'))
    .max(255, longEmailAddress),
})

export const adminServiceValidationSchema: any = object().shape({
  serviceName: string().required('Service Name is required'),
  defaultPrice: string().required('Default Price is required'),
  defaultDuration: string().required('Default Duration is required'),
})

export const adminKanbanValidationSchema: any = object().shape({
  boardName: string().required('Board Name is required'),
})

export const companyContacts = object().shape({
  contacts: array().of(
    object().shape({
      contactFirstName: string().required('First Name is required'),
      contactLastName: string().required('Last Name is required'),
      contactRole: string().required('Contact Role is required'),
      contactEmail: string()
        .email(invalidEmailAddress)
        .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, invalidEmailAddress)
        .test('no-consecutive-dots', invalidEmailAddress, (value: any) => !value?.includes('..'))
        .max(255, longEmailAddress),
    })
  ),
})

export const sites = object().shape({
  postcode: string().required('Postcode is required'),
  siteName: string().required('Site Name is required'),
  siteAddress: string().required('Site Address is required'),
  phoneNumber: string()
    .matches(/^\d{10,11}$/, invalidPhoneNumber)
    .test('numeric-characters-only', invalidPhoneNumber, (value: any) => {
      if (!value) {
        return true
      }
      return /^\d+$/.test(value)
    }),
})

export const companySites = array().of(sites)

export const companyValidationSchema = object().shape({
  companyDetails: object().shape({
    companyType: number().required('Company Type is required'),
    companyName: string().required('Company Name is required'),
    postcode: string().required('Postcode is required'),
    physicalAddress: string().required('Physical Address is required'),
    email: string()
      .email(invalidEmailAddress)
      .test('no-consecutive-dots', invalidEmailAddress, (value: any) => !value?.includes('..'))
      .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, invalidEmailAddress)
      .max(255, longEmailAddress)
      .required(requiredEmailAddress),
    phone: string()
      .matches(/^\d{10,11}$/, invalidPhoneNumber)
      .test('numeric-characters-only', invalidPhoneNumber, (value: any) => /^\d+$/.test(value))
      .required(requiredPhoneNumber),
    jayexAccountManager: string(),
    existingClient: boolean(),
  }),
  companyContacts,
  sites: companySites,
})

export const companyInitialFormValues = {
  companyDetails: {
    companyName: '',
    companyType: '',
    companyAlias: '',
    practiceNumber: '',
    xeroRefNumber: '',
    email: '',
    phone: '',
    postcode: '',
    physicalAddress: '',
    website: '',
    businessRegistrationNumber: '',
    linkedCompanies: [],
    jayexAccountManager: '',
    existingClient: false,
    companyTags: '',
    pastCrmId: '',
    oldCRMLink: '',
  },
  companyContacts: {
    contacts: [
      {
        contactFirstName: '',
        contactLastName: '',
        contactRole: '',
        contactPhone: '',
        contactEmail: '',
        isPrimaryContact: false,
      },
    ],
  },
  sites: [
    {
      postcode: '',
      siteName: '',
      siteAddress: '',
    },
  ],
}
