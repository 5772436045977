import {useKanbanContext} from 'contexts/kanban'
import {Row, Col} from 'react-bootstrap'
import DataTable from 'components/DataTable'

export default function SalesTable() {
  const {project} = useKanbanContext()
  return (
    <Row className="align-items-center justify-content-between">
      <Col>
        <DataTable data={project} columns={kanbanColumnsDef} responsive />
      </Col>
    </Row>
  )
}

export const kanbanColumnsDef: any = [
  {
    name: 'Title',
    selector: (row: any) => row.title,
    sortable: true,
  },
  {
    name: 'Owner',
    selector: (row: any) => row.ownerDisplayName,
    sortable: true,
  },
  {
    name: 'Company',
    selector: (row: any) => row.company,
    sortable: true,
  },
  {
    name: 'kanban type',
    selector: (row: any) => row.kanbanType,
    sortable: true,
  },
  {
    name: 'value',
    selector: (row: any) => row.value,
    sortable: true,
  },
]
