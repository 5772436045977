import {createAsyncThunk} from '@reduxjs/toolkit'
// import {IServiceType} from '../types'
import licenseService from 'services/license/licenseService'

export const fetchServiceType: any = createAsyncThunk(
  'license/fetchServiceType',
  async (token: string) => {
    const serviceType = await licenseService.getServiceType(token)
    return serviceType
  }
)

export const addServiceType: any = createAsyncThunk(
  'license/addServiceType',
  async ({token, data}: any) => {
    const newServiceType = await licenseService.addServiceType(token, data)
    return newServiceType
  }
)

export const deleteServiceType: any = createAsyncThunk(
  'license/deleteServiceType',
  async ({token, id}: {token: string; id: string}) => {
    return await licenseService.deleteServiceType(token, id)
  }
)

export const addLicense: any = createAsyncThunk(
  'license/addLicense',
  async ({token, data}: any) => {
    const newLicense = await licenseService.addLicense(token, data)
    return newLicense
  }
)

export const getAllLicenses: any = createAsyncThunk(
  'license/getAllLicenses',
  async ({token}: {token: string}) => {
    const license = await licenseService.getAllLicenses(token)
    return license
  }
)

export const getLicense: any = createAsyncThunk(
  'license/getLicense',
  async ({token, id}: {token: string; id: string}) => {
    const license = await licenseService.getLicense(token, id)
    return license
  }
)
