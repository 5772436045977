import {createAsyncThunk} from '@reduxjs/toolkit'
import contactService from 'services/contact/contactService'

export const getContact: any = createAsyncThunk(
  'contact/getContact',
  async ({token, contactId}: {token: string; contactId: string}) => {
    const result = await contactService.getContact(token, contactId)
    return result
  }
)

export const getContacts: any = createAsyncThunk('contact/getContacts', async (token: string) => {
  const result = await contactService.getContacts(token)
  return result
})

export const addContact: any = createAsyncThunk(
  'contact/addContact',
  async ({token, data}: {token: string; data: any}) => {
    const result = await contactService.addContact(token, data)
    return result
  }
)

export const editContact: any = createAsyncThunk(
  'contact/editContact',
  async ({token, data, id}: {token: string; data: any; id: string}) => {
    const result = await contactService.editContact(token, data, id)
    return result
  }
)

export const searchContacts: any = createAsyncThunk(
  'contact/searchContacts',
  async ({token, term}: {token: string; term: string}) => {
    const result = await contactService.searchContacts(token, term)
    return result
  }
)

export const linkContact: any = createAsyncThunk(
  'contact/linkContact',
  async ({
    token,
    contactId,
    category,
    categoryId,
  }: {
    token: string
    contactId: string
    category: string
    categoryId: string
  }) => {
    const result = await contactService.linkContact(token, contactId, category, categoryId)
    return result
  }
)
