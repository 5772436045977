import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useKanbanContext} from 'contexts/kanban'
import CollapsibleCard from 'components/CollapsibleCard'
import {StyledLists, StyledList, Issues} from 'styles/styled'
import {isPositionChanged, calculateIssueListPosition, getSortedListIssues} from 'utils/kanban'
import {IssueStatus, IssueStatusCopy, IssueStatusValue, IssueStatusColor} from 'enums/kanban'
import BoardListIssue from 'pages/shared/BoardListIssue'

export default function InstallsKanban() {
  const {project, updateIssue} = useKanbanContext()

  const handleIssueDrop = ({draggableId, destination, source}: any) => {
    if (!isPositionChanged(destination, source)) return

    const issueId = Number(draggableId)

    const currentFields = project.find(({id}: any) => id === issueId)

    const updatedIssue: any = {
      ...currentFields,
      status: destination.droppableId,
      listPosition: calculateIssueListPosition(project, destination, source, issueId),
    }

    updateIssue(currentFields.id, updatedIssue)
  }

  return (
    <DragDropContext onDragEnd={handleIssueDrop}>
      <StyledLists>
        {Object.values(IssueStatus).map((status) => {
          return <BoardList key={status} status={status} project={project} />
        })}
      </StyledLists>
    </DragDropContext>
  )
}

const BoardList = ({status, project}: any) => {
  const filteredListIssues = getSortedListIssues(project, status)

  return (
    <Droppable key={status} droppableId={status}>
      {(provided: any) => (
        <StyledList>
          <CollapsibleCard
            title={IssueStatusCopy[status]}
            count={filteredListIssues?.length}
            isArrow={false}
            value={IssueStatusValue[status]}
            indicatorColor={IssueStatusColor[status]}
          />
          <Issues {...provided.droppableProps} ref={provided.innerRef}>
            {filteredListIssues?.map((issue: any, index: any) => (
              <BoardListIssue key={issue.id} issue={issue} index={index} />
            ))}
            {provided.placeholder}
          </Issues>
        </StyledList>
      )}
    </Droppable>
  )
}
