import {INoteCreate, INoteEdit} from 'types/note'
import {
  requestBase as request,
  IGetAsyncFunction,
  IPostAsyncFunction,
  IPutAsyncFunction,
  handleAxiosError,
} from '../requestBase'

const getAllNotes: IGetAsyncFunction = async (token) => {
  try {
    const result = await request(token).get(`Note`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getNotes: IGetAsyncFunction = async (token, categoryId, category) => {
  try {
    const result = await request(token).get(`Note?CategoryId=${categoryId}&Category=${category}`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const addNote: IPostAsyncFunction = async (token: string, data?: INoteCreate) => {
  try {
    const result = await request(token).post('Note', data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const editNote: IPutAsyncFunction = async (token: string, data?: INoteEdit, noteId?: string) => {
  try {
    const result = await request(token).patch(`Note/${noteId}`, data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const deleteNote: any = async (token: string, noteId?: string) => {
  try {
    const result = await request(token).delete(`Note/${noteId}`)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getNoteHistory: IGetAsyncFunction = async (token, noteId) => {
  try {
    const result = await request(token).get(`Note/history/${noteId}`)
    return result.data?.data
  } catch (err) {
    // handleAxiosError(err)
  }
}

const getRelatedNotes: IGetAsyncFunction = async (token: string, companyId: string) => {
  try {
    const result = await request(token).get(`Note/Related-Notes/${companyId}`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const searchNotes: IGetAsyncFunction = async (token: string, term: string) => {
  try {
    const result = await request(token).get(`Note?search=${term}`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const noteService = {
  getAllNotes,
  getNotes,
  addNote,
  editNote,
  deleteNote,
  getNoteHistory,
  getRelatedNotes,
  searchNotes,
}

export default noteService
