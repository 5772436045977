import {useState, useEffect, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import {translate} from 'i18n'
import {serviceColumnsDef} from 'grids/adminColumnsDef'
import DataTable from 'components/DataTable'
import ReusableModal from 'components/ReusableModal'
// import useAuthentication from 'hooks/useAuthentication'
import AdminServiceForm from 'forms/AdminServiceForm'
import Button from 'react-bootstrap/Button'
import {StyledSearchInput, StyledSubTitle, StyledTitle} from 'styles/styled'
import {Stack} from 'react-bootstrap'
import {fetchServiceType} from 'reduxStore/actions/licenseActions'
import {selectServiceType} from 'reduxStore/selectors/licenseSelectors'
import useAuthentication from 'hooks/useAuthentication'

export default function Services(props: any) {
  const {isServiceModalOpen, setIsServiceModalOpen} = props
  const [searchText, setSearchText] = useState<string>('')
  const [isEdit, setIsEdit] = useState(false)
  const {token} = useAuthentication()

  const dispatch = useDispatch()
  const serviceTypes = useSelector(selectServiceType)

  useEffect(() => {
    if (token) {
      dispatch(fetchServiceType(token))
    }
  }, [dispatch, token])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const filteredData: any = serviceTypes?.filter((item: any) =>
    Object.values(item).some(
      (value) => value && value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  )

  const handleClose = () => {
    setIsServiceModalOpen(false)
    setIsEdit(false)
  }

  return (
    <>
      <Row className="align-items-center justify-content-between">
        <Col md={6}>
          <Stack>
            {/* <StyledSubTitle>{translate('admin.title')}</StyledSubTitle> */}
            <StyledTitle>{translate('admin.services')}</StyledTitle>
          </Stack>
        </Col>

        <Col md={6} className="text-end">
          <Button variant="primary" onClick={() => setIsServiceModalOpen(true)}>
            {translate('admin.addServiceType')}
          </Button>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-between mt-3">
        <Col md={4}>
          <Form>
            <StyledSearchInput
              className="mb-2"
              placeholder={translate('admin.searchService')}
              onChange={handleSearch}
            />
          </Form>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <DataTable data={filteredData} columns={serviceColumnsDef} responsive />
        </Col>
      </Row>

      <ReusableModal
        showModal={isServiceModalOpen}
        handleClose={handleClose}
        modalTitle={'Add a service'}
        showFooter={false}
        modalBody={<AdminServiceForm handleClose={handleClose} />}
      />
    </>
  )
}
