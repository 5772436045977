import {createAsyncThunk} from '@reduxjs/toolkit'
import noteService from 'services/note/ noteService'

export const getAllNotes: any = createAsyncThunk('note/getAllNotes', async (token: string) => {
  const result = await noteService.getAllNotes(token)
  return result
})

export const getNotes: any = createAsyncThunk(
  'note/getNotes',
  async ({token, categoryId, category}: {token: string; categoryId: string; category: string}) => {
    const result = await noteService.getNotes(token, categoryId, category)
    return result
  }
)

export const addNote: any = createAsyncThunk(
  'note/addNote',
  async ({token, data}: {token: string; data: any}) => {
    const result = await noteService.addNote(token, data)
    return result
  }
)

export const editNote: any = createAsyncThunk(
  'note/editNote',
  async ({token, data, noteId}: {token: string; data: any; noteId: string}) => {
    const result = await noteService.editNote(token, data, noteId)
    return result
  }
)

export const deleteNote: any = createAsyncThunk(
  'note/deleteNote',
  async ({token, noteId}: {token: string; noteId: string}) => {
    const result = await noteService.deleteNote(token, noteId)
    return result
  }
)

export const getNoteHistory: any = createAsyncThunk(
  'note/getNoteHistory',
  async ({token, noteId}: {token: string; noteId: string}) => {
    const result = await noteService.getNoteHistory(token, noteId)
    return result
  }
)

export const getRelatedNotes: any = createAsyncThunk(
  'note/getRelatedNotes',
  async ({token, companyId, term}: {token: string; companyId: string; term: string}) => {
    const result = await noteService.getRelatedNotes(token, companyId, term)
    return result
  }
)

export const searchNotes: any = createAsyncThunk(
  'note/searchNotes',
  async ({token, term}: {token: string; term: string}) => {
    const result = await noteService.searchNotes(token, term)
    return result
  }
)
