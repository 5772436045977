// src/reducers/noteSlice.js
import {createSlice} from '@reduxjs/toolkit'
import {
  getAllNotes,
  getNotes,
  addNote,
  editNote,
  deleteNote,
  getNoteHistory,
  getRelatedNotes,
  searchNotes,
} from '../actions/noteActions'

const initialState = {
  history: {},
  allNotes: [],
  notes: [],
  relatedNotes: [],
  isLoading: false,
  isError: false,
}

const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotes.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getAllNotes.fulfilled, (state, action) => {
        state.allNotes = action.payload
        state.isLoading = false
      })
      .addCase(getAllNotes.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getNotes.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getNotes.fulfilled, (state, action) => {
        state.notes = action.payload
        state.isLoading = false
      })
      .addCase(getNotes.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(addNote.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(addNote.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(editNote.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(editNote.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(editNote.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(deleteNote.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(deleteNote.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getNoteHistory.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getNoteHistory.fulfilled, (state, action) => {
        state.history = action.payload
        state.isLoading = false
      })
      .addCase(getNoteHistory.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getRelatedNotes.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getRelatedNotes.fulfilled, (state, action) => {
        state.relatedNotes = action.payload
        state.isLoading = false
      })
      .addCase(getRelatedNotes.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(searchNotes.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(searchNotes.fulfilled, (state, action) => {
        state.notes = action.payload
        state.isLoading = false
      })
      .addCase(searchNotes.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
  },
})

export {getAllNotes, getNotes, addNote, editNote, deleteNote, searchNotes}
export default noteSlice.reducer
