import {Route, Routes} from 'react-router-dom'
import {lazy, Suspense} from 'react'
import PageLoader from 'components/PageLoader'
import RoutePaths from 'enums/routes'

const SiteDetail = lazy(() => import(/* webpackChunkName: "SiteDetail" */ 'pages/site/detail/'))

export default function SiteRoutes() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {/* <Route path={RoutePaths.HOME} element={} /> */}
        <Route path={RoutePaths.SITE_DETAILS + '*'} element={<SiteDetail />} />
      </Routes>
    </Suspense>
  )
}
