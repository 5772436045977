import Spinner from 'react-bootstrap/Spinner'
import {StyledPageLoader} from '../styles/styled'
import Logo from 'assets/images/logo.png'

const PageLoader: React.FC = () => {
  return (
    <StyledPageLoader>
      <img alt="Logo" src={Logo} height="30" className="d-inline-block align-top" />
      <Spinner
        animation="border"
        variant="primary"
        role="status"
        style={{marginLeft: '50px', marginTop: '5px'}}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </StyledPageLoader>
  )
}

export default PageLoader
