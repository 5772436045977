import {moveItemWithinArray, insertItemIntoArray} from './javascript'

export const isPositionChanged = (destination: any, source: any) => {
  if (!destination) return false
  const isSameList = destination?.droppableId === source?.droppableId
  const isSamePosition = destination?.index === source?.index
  return !isSameList || !isSamePosition
}

export const calculateIssueListPosition = (
  allIssues: any[],
  destination: any,
  source: any,
  issueId: number
) => {
  const {prevIssue, nextIssue}: any = getAfterDropPrevNextIssue(
    allIssues,
    destination,
    source,
    issueId
  )

  let position

  if (!prevIssue && !nextIssue) {
    position = 1
  } else if (!prevIssue) {
    position = nextIssue.listPosition - 1
  } else if (!nextIssue) {
    position = prevIssue.listPosition + 1
  } else {
    position = prevIssue.listPosition + (nextIssue.listPosition - prevIssue.listPosition) / 2
  }
  return position
}

export const getAfterDropPrevNextIssue = (
  allIssues: any[],
  destination: any,
  source: any,
  droppedIssueId: number
) => {
  const beforeDropDestinationIssues = getSortedListIssues(allIssues, destination.droppableId)

  const droppedIssue = allIssues.find((issue) => issue.id === droppedIssueId)
  const isSameList = destination.droppableId === source.droppableId

  const afterDropDestinationIssues = isSameList
    ? moveItemWithinArray(beforeDropDestinationIssues, droppedIssue, destination.index)
    : insertItemIntoArray(beforeDropDestinationIssues, droppedIssue, destination.index)

  return {
    prevIssue: afterDropDestinationIssues[destination.index - 1],
    nextIssue: afterDropDestinationIssues[destination.index + 1],
  }
}

export const getSortedListIssues = (issues: any[], status: string) => {
  return issues
    ?.filter((issue) => issue.status === status)
    .sort((a, b) => a.listPosition - b.listPosition)
}
