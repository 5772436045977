import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {StyledModal, StyledModalHeader, StyledModalFooter} from 'styles/styled'

interface BasicModalProps {
  showModal: boolean
  modalTitle: string
  modalBody: React.ReactNode
  primaryButtonText?: string
  handleClose: () => void
  onPrimaryButtonClick?: () => void
  hidePrimaryButton?: boolean
  isButtonDisabled?: any
  size?: any
}

const BasicModal: React.FC<BasicModalProps> = (props) => {
  const {
    showModal,
    modalTitle,
    modalBody,
    primaryButtonText,
    handleClose,
    onPrimaryButtonClick,
    isButtonDisabled = false,
    size = 'md',
  } = props

  return (
    <StyledModal show={showModal} onHide={handleClose} style={{padding: '40px'}} size={size}>
      <StyledModalHeader closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </StyledModalHeader>
      <Modal.Body>{modalBody}</Modal.Body>
      <StyledModalFooter>
        {onPrimaryButtonClick && (
          <Button disabled={isButtonDisabled} variant="primary" onClick={onPrimaryButtonClick}>
            {primaryButtonText}
          </Button>
        )}
        <Button variant="outline-primary" onClick={handleClose}>
          Cancel
        </Button>
      </StyledModalFooter>
    </StyledModal>
  )
}

export default BasicModal
