import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {StyledModal, StyledModalHeader, StyledModalFooter} from 'styles/styled'

interface ReusableModalProps {
  showModal: boolean
  modalTitle: string
  modalBody: React.ReactNode
  primaryButtonText?: string
  handleClose: () => void
  onPrimaryButtonClick?: () => void
  render?: any
  hidePrimaryButton?: boolean
  hideCloseButton?: boolean
  isButtonDisabled?: any
  showFooter?: boolean
  size?: any
}

const ReusableModal: React.FC<ReusableModalProps> = (props) => {
  const {
    showModal,
    modalTitle,
    modalBody,
    primaryButtonText,
    handleClose,
    onPrimaryButtonClick,
    render,
    hidePrimaryButton = false,
    hideCloseButton = false,
    isButtonDisabled = false,
    showFooter = true,
    size = 'md',
  } = props

  return (
    <StyledModal show={showModal} onHide={handleClose} style={{padding: '40px'}} size={size}>
      <StyledModalHeader closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </StyledModalHeader>
      <Modal.Body>{modalBody}</Modal.Body>
      {showFooter && (
        <StyledModalFooter $applymargin={!!render}>
          {/* TODO: Relook this logic */}
          {!hidePrimaryButton && (
            <Button disabled={isButtonDisabled} variant="primary" onClick={onPrimaryButtonClick}>
              {primaryButtonText}
            </Button>
          )}
          {render}

          {!hideCloseButton && (
            <Button variant="outline-primary" onClick={handleClose}>
              Cancel
            </Button>
          )}
        </StyledModalFooter>
      )}
    </StyledModal>
  )
}

export default ReusableModal
