import {useState, KeyboardEventHandler} from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import {translate} from 'i18n'
import {Formik, Form as FormikForm} from 'formik'
import {adminKanbanValidationSchema} from 'forms/validations/companyValidationSchema'
import RequiredFieldLabel from 'components/RequiredFieldLabel'
import CreatableSelect from 'react-select/creatable'

interface AdminKanbanFormValues {
  id?: string | number
  type: string
  boardName: string
  columns: any
}

interface CreateSelectOption {
  readonly label: string
  readonly value: string
}

const createOption = (label: string) => ({
  label,
  value: label,
})

const components = {
  DropdownIndicator: null,
}

const types = ['opportunity', 'survey']

const AdminServiceForm = (props: any) => {
  const {handleClose, setRenderPropData} = props
  // const {token} = useAuthentication()
  // const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState<readonly CreateSelectOption[]>([])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialModalFormState}
      validationSchema={adminKanbanValidationSchema}
      onSubmit={async (values: AdminKanbanFormValues, actions) => {
        // await dispatch(addServiceType({token, data: values}))
        handleClose?.()
        // await dispatch(fetchServiceType(token))
        // actions.resetForm()
        setRenderPropData((prevData: any) => [...prevData, values])
      }}
    >
      {({handleBlur, handleChange, values, touched, errors, dirty}: any) => {
        const handleKeyDown: KeyboardEventHandler = (event: any) => {
          if (!inputValue) return
          switch (event.key) {
            case 'Enter':
            case 'Tab':
              setValue((prev) => {
                const newValue = [...prev, createOption(inputValue)]
                handleChange({
                  target: {
                    name: 'columns',
                    value: newValue.map((item) => item.label),
                  },
                })
                handleBlur({target: {name: 'columns'}})
                setInputValue('')
                return newValue
              })
              event.preventDefault()
              break
            default:
              break
          }
        }

        const handleTagRemove = (removedTag: any) => {
          setValue((prev) => {
            const newValue = prev.filter((tag) => tag.label !== removedTag.label)
            handleChange({
              target: {
                name: 'columns',
                value: newValue.map((item) => item.label),
              },
            })
            return newValue
          })
        }

        return (
          <FormikForm>
            <FormikForm>
              <Form.Group className="mb-3" controlId="boardName">
                <Form.Label>
                  <RequiredFieldLabel label={translate('admin.boardName')} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="boardName"
                  value={values?.boardName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched?.boardName && errors?.boardName}
                  autoFocus
                  placeholder="Enter board name"
                />

                <div className="invalid-feedback error">{errors?.boardName}</div>
              </Form.Group>
              <Form.Group className="mb-3 validation-field" controlId="status">
                <Form.Label>
                  <RequiredFieldLabel label={translate('admin.type')} />
                </Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.type}
                >
                  <option>{translate('global.select')}</option>
                  {types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="columnName">
                <Form.Label>{translate('admin.columnName')}</Form.Label>

                <CreatableSelect
                  components={components}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue, actionMeta) => {
                    if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
                      handleTagRemove(actionMeta.removedValue)
                    } else {
                      setValue(newValue)
                    }
                  }}
                  onInputChange={(newValue) => setInputValue(newValue)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type a column and press enter..."
                  value={value}
                />
              </Form.Group>
            </FormikForm>

            <div>
              <h4>{values.boardName}</h4>
              <ListGroup>
                {values.columns.map((column: any, idx: any) => (
                  <ListGroup.Item key={idx}>{column}</ListGroup.Item>
                ))}
              </ListGroup>
            </div>

            <div className="d-flex justify-content-end gap-2 mt-5">
              <Button
                type="submit"
                variant="primary"
                disabled={!dirty || Object.keys(errors)?.length > 0}
              >
                {translate('global.create')}
              </Button>
              <Button variant="outline-primary" onClick={handleClose}>
                {translate('global.cancel')}
              </Button>
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  )
}

const initialModalFormState: AdminKanbanFormValues = {
  boardName: '',
  type: '',
  columns: [],
}

export default AdminServiceForm
