import {createAsyncThunk} from '@reduxjs/toolkit'
import serviceService from 'services/service/addressService'

export const addressSearch: any = createAsyncThunk(
  'service/addressSearch',
  async ({token, address}: {token: string; address: string}) => {
    const result = await serviceService.addressSearch(token, address)
    return result
  }
)

export const addressGet: any = createAsyncThunk(
  'service/addressGet',
  async ({token, addressId}: {token: string; addressId: string}) => {
    const result = await serviceService.addressGet(token, addressId)
    return result
  }
)
