enum RoutePaths {
  HOME = '/',
  DASHBOARD = '/dashboard',
  ADMIN = '/admin',
  COMPANIES = '/companies',
  SITES = '/sites',
  COMPANY_OVERVIEW = '/overview',
  CONTACTS = '/contacts',
  LICENSES = '/licenses',
  CREATE = '/create',
  EDIT = '/edit/:id',
  SALES = '/sales',
  INSTALLS = '/installs',
  OPPORTUNITIES = 'opportunities',
  PRODUCTS = 'products',
  TASKS = '/tasks',
  ACCOUNTING = '/accounting',
  COMPANY_DETAILS = '/:id/',
  COMPANIES_OVERVIEW = '/overview',
  COMPANY_NOTES = '/notes',
  COMPANY_EMAILS = '/companies/emails',
  COMPANY_CALLS = '/companies/calls',
  COMPANY_TASKS = '/companies/tasks',
  COMPANY_MEETINGS = '/companies/meetings',
  COMPANY_SOLUTIONS = '/companies/solutions',
  SITE_DETAILS = '/:id/',
}

export type RoutePath = keyof typeof RoutePaths
export default RoutePaths
