import {ISiteCreate, ISiteEdit} from 'types/site'
import {
  requestBase as request,
  IGetAsyncFunction,
  IPostAsyncFunction,
  handleAxiosError,
} from '../requestBase'

const getSite = async (token: string, siteId: string) => {
  try {
    const result = await request(token).get(`Site/${siteId}`)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getSites: IGetAsyncFunction = async (token) => {
  try {
    const result = await request(token).get('Site')
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const addSite: IPostAsyncFunction = async (token: string, data?: ISiteCreate[]) => {
  try {
    const result = await request(token).post('Site', data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const editSite = async (token: string, siteId: string, data?: ISiteEdit) => {
  try {
    const result = await request(token).put(`Site/${siteId}`, data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const deleteSite: any = async (token: string, siteId?: string) => {
  try {
    const result = await request(token).delete(`Site/${siteId}`)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const siteService = {
  getSite,
  getSites,
  addSite,
  editSite,
  deleteSite,
}

export default siteService
