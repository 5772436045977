enum Permissions {
  super_permission = 'SUPER PERMISSION',
  note_edit = 'EDIT NOTE',
  note_delete = 'DELETE NOTE',
  note_history_view = 'VIEW NOTE HISTORY',
  site_create = 'CREATE SITE',
  site_edit = 'EDIT SITE',
  site_delete = 'DELETE SITE',
  company_create = 'CREATE COMPANY',
  company_edit = 'EDIT COMPANY',
  company_delete = 'DELETE COMPANY',
  deal_create = 'CREATE DEAL',
  deal_edit = 'EDIT DEAL',
  deal_delete = 'DELETE DEAL',
  user_create = 'CREATE USER',
  user_edit = 'EDIT USER',
  user_delete = 'DELETE USER',
  user_extended_view = 'VIEW EXTENDED USER',
  license_create = 'CREATE LICENSE',
  license_edit = 'EDIT LICENSE',
  license_delete = 'DELETE LICENSE',
}

export type PermissionsType = keyof typeof Permissions
export default Permissions
