/* eslint-disable no-case-declarations */
import {createContext, useContext, useReducer, ReactNode} from 'react'
import {projectData} from './data'

type Issue = any
type Project = any[]

interface State {
  project: Project
}

type Action =
  | {type: 'ADD_ISSUE'; payload: Issue}
  | {type: 'UPDATE_ISSUE'; payload: {id: string; updatedIssue: Issue}}

interface ContextValue {
  project: Project
  addIssue: (newIssue: Issue) => void
  updateIssue: (id: string, updatedIssue: Issue) => void
}

const KanbanContext = createContext<ContextValue | undefined>(undefined)

interface ProjectProviderProps {
  children: ReactNode
}

const initialState: State = {
  project: projectData,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ADD_ISSUE':
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload,
        },
      }
    case 'UPDATE_ISSUE':
      // Update an existing issue in the project
      const updatedIssues = state.project.map((issue) =>
        issue.id === action.payload.id ? action.payload.updatedIssue : issue
      )
      return {
        ...state,
        project: updatedIssues,
      }
    default:
      return state
  }
}

export const KanbanProvider = ({children}: ProjectProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const addIssue = (newIssue: Issue): void => {
    console.log('newIssue', newIssue)

    const tempNewIssue = {...newIssue, status: 'pending'}

    dispatch({type: 'ADD_ISSUE', payload: tempNewIssue})
  }

  const updateIssue = (id: string, updatedIssue: Issue): void => {
    dispatch({type: 'UPDATE_ISSUE', payload: {id, updatedIssue}})
  }

  return (
    <KanbanContext.Provider value={{project: state.project, addIssue, updateIssue}}>
      {children}
    </KanbanContext.Provider>
  )
}

export const useKanbanContext = (): ContextValue => {
  const context = useContext(KanbanContext)
  if (!context) {
    throw new Error('useKanbanContext must be used within a ProjectProvider')
  }
  return context
}
