import {useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {translate} from 'i18n'
import useAuthentication from 'hooks/useAuthentication'
import {Formik, Form as FormikForm} from 'formik'
import {adminServiceValidationSchema} from 'forms/validations/companyValidationSchema'
import RequiredFieldLabel from 'components/RequiredFieldLabel'
import {addServiceType, fetchServiceType} from 'reduxStore/actions/licenseActions'

interface AdminServiceFormValues {
  id?: string | number
  serviceName: string
  defaultPrice: string | number
  defaultDuration: string | number
}

const AdminServiceForm = (props: any) => {
  const {handleClose} = props
  const {token} = useAuthentication()
  const dispatch = useDispatch()

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialModalFormState}
      validationSchema={adminServiceValidationSchema}
      onSubmit={async (values: AdminServiceFormValues, actions) => {
        await dispatch(addServiceType({token, data: values}))
        handleClose?.()
        await dispatch(fetchServiceType(token))
        actions.resetForm()
      }}
    >
      {({handleBlur, handleChange, values, touched, errors, dirty}: any) => {
        return (
          <FormikForm>
            <FormikForm>
              <Form.Group className="mb-3" controlId="serviceName">
                <Form.Label>
                  <RequiredFieldLabel label={translate('grid.admin.serviceName')} />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="serviceName"
                  value={values?.serviceName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched?.serviceName && errors?.serviceName}
                  autoFocus
                />
                <div className="invalid-feedback error">{errors?.serviceName}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="defaultPrice">
                <Form.Label>
                  <RequiredFieldLabel label={translate('grid.admin.defaultPrice')} />
                </Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  name="defaultPrice"
                  value={values?.defaultPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched?.defaultPrice && errors?.defaultPrice}
                />
                <div className="invalid-feedback error">{errors?.defaultPrice}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="defaultDuration">
                <Form.Label>
                  <RequiredFieldLabel
                    label={translate('grid.admin.defaultDuration') + ' (months)'}
                  />
                </Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  name="defaultDuration"
                  value={values?.defaultDuration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched?.defaultDuration && errors?.defaultDuration}
                />
                <div className="invalid-feedback error">{errors?.defaultDuration}</div>
              </Form.Group>
            </FormikForm>
            <div className="d-flex justify-content-end gap-2 mt-5">
              <Button
                type="submit"
                variant="primary"
                disabled={!dirty || Object.keys(errors)?.length > 0}
              >
                {translate('global.create')}
              </Button>
              <Button variant="outline-primary" onClick={handleClose}>
                {translate('global.cancel')}
              </Button>
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  )
}

const initialModalFormState: AdminServiceFormValues = {
  serviceName: '',
  defaultPrice: '',
  defaultDuration: '',
}

export default AdminServiceForm
