import Badge from 'react-bootstrap/Badge'
import {translate} from 'i18n'
import {formatDate} from 'utils/dateTime'
import LicenseColumnAction from './licenseColumnAction'

const sharedColumnProps = {
  minWidth: '100px',
}

export const serviceColumnsDef: any = [
  {
    name: translate('grid.admin.serviceName'),
    selector: (row: any) => row.serviceName,
    sortable: true,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.defaultPrice'),
    selector: (row: any) => row.defaultPrice,
    sortable: true,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.defaultDuration'),
    selector: (row: any) => row.defaultDuration,
    ...sharedColumnProps,
  },
  {
    allowOverflow: true,
    width: '60px',
    cell: (license: any) => <LicenseColumnAction license={license} />,
  },
]

export const usersColumnsDef: any[] = [
  {
    name: translate('grid.admin.firstName'),
    selector: (row: any) => row.firstName,
    sortable: true,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.lastName'),
    selector: (row: any) => row.lastName,
    sortable: true,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.email'),
    selector: (row: any) => row.emailAddress,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.alias'),
    selector: (row: any) => row.alias,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.userRole'),
    minWidth: '150px',
    cell: (row: any) => <UserRolesWrapper row={row} />,
  },
  {
    name: translate('grid.admin.lastActive'),
    selector: (row: any) => formatDate(row.lastActiveDate),
    sortable: true,
    ...sharedColumnProps,
  },
]

const UserRolesWrapper = ({row}: any) => (
  <div
    style={{
      padding: '10px',
    }}
  >
    <Badge bg="secondary" className="me-1">
      {row.role}
    </Badge>
  </div>
)
