import {useState} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Users from './users'
import Services from './services'
import Sider from 'components/Sider'
import {Routes, Route, Navigate} from 'react-router-dom'
import Kanban from './kanban'

export default function AdminPanel() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false)
  const [isNavMenuClose, setIsNavMenuClose] = useState(false)

  const handleCollapse = () => {
    setIsNavMenuClose(!isNavMenuClose)
  }

  return (
    <>
      <Container
        style={{
          paddingBottom: '0px !important',
          paddingTop: '0px !important',
          paddingLeft: '12px !important',
          height: '100vh',
        }}
      >
        <Row style={{height: '100%'}}>
          <Col
            md={2}
            className="p-4"
            style={{
              background: '#f4f4f4',
              borderBottomLeftRadius: '16px',
              borderRight: '1px solid #1a202d',
            }}
          >
            <Sider isNavMenuClose={isNavMenuClose} onCollapse={handleCollapse} />
          </Col>
          <Col md={10} style={{padding: '3rem'}}>
            <Routes>
              <Route path="/" element={<Navigate to="users" />} />
              <Route
                path="users"
                element={<Users isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
              />
              <Route
                path="services"
                element={
                  <Services
                    isServiceModalOpen={isServiceModalOpen}
                    setIsServiceModalOpen={setIsServiceModalOpen}
                  />
                }
              />
              <Route path="kanban" element={<Kanban />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  )
}
