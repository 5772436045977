// src/reducers/authSlice.js
import {createSlice} from '@reduxjs/toolkit'
import {
  acquireTokenByCode,
  isAuthenticated,
  getUsers,
  getUser,
  addUser,
  updatedUser,
  getRoles,
  getPermissions,
} from '../actions/authActions'

const initialState = {
  isAuthenticated: false,
  user: {},
  users: [],
  roles: [],
  permissions: [],
  isLoading: false,
  isError: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(acquireTokenByCode.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(acquireTokenByCode.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(acquireTokenByCode.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(isAuthenticated.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(isAuthenticated.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload
        state.isLoading = false
      })
      .addCase(isAuthenticated.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload
        state.isLoading = false
      })
      .addCase(getUsers.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload
        state.isLoading = false
      })
      .addCase(getUser.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(addUser.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(addUser.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(updatedUser.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(updatedUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updatedUser.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getRoles.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload
        state.isLoading = false
      })
      .addCase(getRoles.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload
        state.isLoading = false
      })
  },
})

export default authSlice.reducer
