import BasicModal from 'components/modals/BasicModal'
import useAuthentication from 'hooks/useAuthentication'
import {useState} from 'react'
import {Stack} from 'react-bootstrap'
import {Trash} from 'react-bootstrap-icons'
import {useDispatch, useSelector} from 'react-redux'
import {deleteServiceType, fetchServiceType} from 'reduxStore/actions/licenseActions'
import {selectIsLoading} from 'reduxStore/selectors/licenseSelectors'
import {StyledClickable} from 'styles/styled'

export default function LicenseColumnAction({license}: {license: any}) {
  const {id, serviceName} = license
  const [open, setOpen] = useState(false)
  const {token} = useAuthentication()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoading)

  const handleDeleteService = () => {
    dispatch(deleteServiceType({token, id}))
    setOpen(false)
    dispatch(fetchServiceType(token))
  }

  return (
    <>
      <Stack direction="horizontal" gap={2}>
        <StyledClickable onClick={() => setOpen(true)}>
          <Trash />
        </StyledClickable>
      </Stack>
      <BasicModal
        showModal={open}
        handleClose={() => setOpen(false)}
        modalTitle={`Archive "${serviceName}"`}
        primaryButtonText={'Continue'}
        isButtonDisabled={isLoading}
        onPrimaryButtonClick={handleDeleteService}
        modalBody={<span>Do you wish to archive this service?</span>}
      />
    </>
  )
}
