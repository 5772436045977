import {ApplicationEnvironmentEnum} from 'enums/environment'
import {IApplicationEnvironmentsType, IApplicationEnvironmentType} from 'types/environment'
import production from './production.json'
import stage from './stage.json'
import dev from './dev.json'
const development = require('./development.json') ?? {...stage}

const environments: IApplicationEnvironmentsType = {
  production,
  stage,
  development,
  dev,
}

const environment: IApplicationEnvironmentType = {
  env: process.env.REACT_APP_NODE_ENV,
  DEFAULT_LOCALE: 'en-GB',
  LOCALE: 'en-GB',
  ...environments[
    typeof process.env.REACT_APP_NODE_ENV === 'string'
      ? process.env.REACT_APP_NODE_ENV
      : ApplicationEnvironmentEnum.Production.toString()
  ],
}

export const isDevelopment = () => {
  return environment.env === ApplicationEnvironmentEnum.Development
}

export default environment
