import {createAsyncThunk} from '@reduxjs/toolkit'
import siteService from 'services/site/siteService'

export const getSite: any = createAsyncThunk(
  'site/getSite',
  async ({token, siteId}: {token: string; siteId: string}) => {
    const result = await siteService.getSite(token, siteId)
    return result
  }
)

export const getSites: any = createAsyncThunk('site/getSites', async (token: string) => {
  const result = await siteService.getSites(token)
  return result
})

export const addSite: any = createAsyncThunk(
  'site/addSite',
  async ({token, data}: {token: string; data: any}) => {
    const result = await siteService.addSite(token, data)
    return result
  }
)

export const editSite: any = createAsyncThunk(
  'site/editSite',
  async ({token, siteId, data}: {token: string; siteId: string; data: any}) => {
    const result = await siteService.editSite(token, siteId, data)
    return result
  }
)

export const deleteSite: any = createAsyncThunk(
  'site/deleteSite',
  async ({token, siteId}: {token: string; siteId: string}) => {
    const result = await siteService.deleteSite(token, siteId)
    return result
  }
)
