import axios, {AxiosError} from 'axios'
import environment, {isDevelopment} from '../config/environment'
import {loginUrl} from 'contexts/config'
import {toast} from 'react-toastify'
import ErroToast from 'components/ErrorToast'

export type IGetAsyncFunction = (authToken: string, param1?: any, param2?: any) => Promise<any>

export type IPostAsyncFunction<T = any> = (
  authToken: string,
  data?: T,
  id?: string,
  param?: any
) => Promise<any>

export type IPutAsyncFunction<T = any> = (authToken: string, data?: T, id?: string) => Promise<any>

export type IPagingGetAsyncFunction = (
  authToken: string,
  page: number,
  offset: number
) => Promise<any>

const defaultHeaders = {
  'Content-Type': 'application/json;charset=UTF-8',
}

const addAuthorizationHeader = (authToken: string, currentHeaderObject: any) => {
  currentHeaderObject.Authorization = `Bearer ${authToken}`
  return currentHeaderObject
}

const requestHandler = (request: any) => {
  return request
}

const errorHandler = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    window.location.href = loginUrl
  }

  //TODO: Revisit this implementation
  return Promise.reject(error)
}

const successHandler = (response: any) => {
  return response
}

const requestBase = (authToken?: string, contentType?: string) => {
  let headers = defaultHeaders

  if (contentType !== undefined) {
    headers['Content-Type'] = contentType
  }

  if (authToken) {
    headers = addAuthorizationHeader(authToken, headers)
  }

  const instance = axios.create({
    baseURL: environment.api,
    headers: headers,
  })

  instance.interceptors.request.use((request: any) => requestHandler(request))

  instance.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => errorHandler(error)
  )

  return instance
}

const handleAxiosError = (error: AxiosError | any) => {
  if (error.response && error.response.status === 400) {
    toast.error(<ErroToast title={error.response.data.title} errors={error.response.data.errors} />)
    throw error
  } else {
    toast.error(`${error.message}. Please try again.`)
    throw error
  }
}

export {requestBase, handleAxiosError}
