import {I18n} from 'i18n-js'
import en from './translation/en'

const i18n = new I18n({
  en,
})

i18n.defaultLocale = 'en'
i18n.locale = 'en'

export function translate(key: string, options?: {[key: string]: string | number}) {
  return key ? i18n?.translate(key, options) : ''
}
