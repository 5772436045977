import {createSelector} from '@reduxjs/toolkit'

export const selectLicense = (state: any) => state.license

export const selectLicenseData = createSelector(selectLicense, (license) => license.data)
export const selectAllLicenseData = createSelector(selectLicense, (license) => license.licence)
export const selectServiceType = createSelector(selectLicense, (license) => license.serviceType)

export const selectIsLoading = createSelector(selectLicense, (license) => license.isLoading)

export const selectIsError = createSelector(selectLicense, (license) => license.isError)
