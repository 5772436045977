import {styled, css} from 'styled-components'
import DataTableComponent from 'react-data-table-component'
import Container from 'react-bootstrap/Container'
import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Modal from 'react-bootstrap/Modal'
import {BaseColors} from 'enums/colors'

export const StyledLogo = styled(Image)`
  border-radius: 4px;
  margin: 0 auto;
`

export const StyledPageTitle = styled.h2`
  font-size: 24px;
  margin-top: 20px;
  color: ${BaseColors.BASE800};
  font-weight: 700;
  text-align: left;
`

export const StyledSearchInput = styled(Form.Control)`
  border: 2px solid ${BaseColors.BASE900};
`

export const StyledClickable = styled.span`
  cursor: pointer;
  user-select: none;
`
export const StyledPageLoader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 38px;
  margin-left: -16px;
  margin-top: -19px;
`
export const StyledDataTable: any = styled(DataTableComponent)`
  border: 2px solid #111;
  border-radius: 6px;
`
export const StyledPagination = styled(Pagination)`
  color: #e2e7f0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .page-link {
    border-radius: 6px;
  }
`

export const StyledAppContainer = styled(Container)`
  border: 4px solid;
  border-radius: 16px;
  padding: 0px;
  margin-top: 6px;
  min-height: 100vh;
`

export const StyledAppInnerContainer = styled(Container)`
  min-height: 100vh;
`

export const StyledAppRow = styled(Row)`
  border-bottom: 2px solid;
  padding: 0 1rem;
`

export const StyledSubTitle = styled.h3`
  color: #717d96;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.14px;
`

export const StyledTitle = styled.h2`
  font-size: 24px;
  color: ${BaseColors.BASE800};
  font-weight: 700;
  text-align: left;
  margin-bottom: 40px;
`

export const StyledRow = styled(Row)`
  border: 2px solid #111;
  border-radius: 4px;
`

export const StyledSider = styled(Col)`
  border-right: 2px solid #111;
  padding: 0;
  margin: 0;
`

export const StyledNavLink = styled(Nav.Link)``

export const StyledItem = styled(Nav.Item)`
  .active {
    background: #e2e7f0;
    color: #111;
  }
`

export const StyledNav = styled(Nav)`
  padding: 0;
  margin: 0;
`

export const StyledTabContent = styled(Tab.Content)`
  padding: 20px;
`
export const StyledContentTitle = styled.h3`
  color: #2d3648;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 24px;
`

export const StyledContentSubTitle = styled.h3`
  color: #2d3648;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 24px;
`

export const StyledLinkButton = styled.span`
  color: #2d3648;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 24px;
  display: block;
`

export const StyledCard = styled(Card)`
  background: ${(props) => props?.color ?? '#ffffff'};
  border: 1px solid #1a202c;
  padding: 15px;
  border-radius: 0px;
  margin-bottom: 24px;
`

export const StyledBadge = styled(Badge)`
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
  margin-left: 6px;
  &.outlined {
    color: #1a202d;
    background: white !important;
    border: 1px solid #1a202d;
  }
`
export const StyledFormLabel = styled(Form.Label)`
  color: #2d3648;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.14px;
`

export const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid #2d3648;
    border-radius: 4px;
    padding: 20px;
  }
`

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom: none;
`

export const StyledModalFooter = styled(Modal.Footer)<{$applymargin: boolean}>`
  border-top: none;
  display: flex;
  justify-content: space-between;
  ${(props) =>
    !props.$applymargin &&
    `  > button:first-of-type {
  margin-left: auto;
}`}
`

export const StyledIconWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`
export const StyledLists = styled.div`
  display: flex;
  height: 100%;
  overflow-x: scroll;
`

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  min-height: 400px;
  min-width: 24%;
  border-radius: 3px;
  background: #edf0f7;
  overflow-x: scroll;
`

export const Issues = styled.div`
  height: 100%;
  padding: 0 5px;
`

export const IssueWrapper = styled.div<{isBeingdragged: boolean}>`
  cursor: pointer;
  user-select: none;
  ${(props) =>
    props.isBeingdragged &&
    css`
      transform: rotate(3deg);
      box-shadow: 5px 10px 30px 0px rgba(9, 30, 66, 0.15);
    `}
`

export const Issue = styled.div`
  padding: 10px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
  transition: background 0.1s;
  cursor: pointer;
  user-select: none;
  @media (max-width: 1100px) {
    padding: 10px 8px;
  }
  &:hover {
    background: #ebebeb;
  }
`
