const getValueForKey = (enumKey: string | number, enumType: any): string | number | undefined => {
  const mappedKey: keyof typeof enumType = enumKey as any
  if (enumType[mappedKey]) {
    return enumType[mappedKey]
  } else {
    return undefined
  }
}

const getKeyForValue = (enumType: any, value: string | number): string => {
  return Object.keys(enumType)[Object.values(enumType).indexOf(value)]
}

const enumHelper = {
  getValueForKey,
  getKeyForValue,
}

export default enumHelper
