import {createSlice} from '@reduxjs/toolkit'
import {
  getCompany,
  editCompany,
  getCompanies,
  getCompanySummaries,
  addCompany,
  getCompanyTypes,
  getOpportunities,
  getContracts,
  getRelatedContacts,
  getRelatedCompanies,
  getXeroRef,
  searchCompany,
  addLinkedCompany,
  deleteLinkedCompany,
} from '../actions/companyActions'

const initialState = {
  data: {},
  companies: [],
  summaries: [],
  companyTypes: [],
  opportunities: [],
  contracts: [],
  relatedContacts: [],
  relatedCompanies: [],
  xeroRef: [],
  searchResults: [],
  links: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.data = action.payload?.data
        state.links = action.payload?.links
        state.isLoading = false
      })
      .addCase(getCompany.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(editCompany.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(editCompany.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(editCompany.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getCompanies.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.companies = action.payload
        state.isLoading = false
      })
      .addCase(getCompanies.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getCompanySummaries.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getCompanySummaries.fulfilled, (state, action) => {
        state.summaries = action.payload
        state.isLoading = false
      })
      .addCase(getCompanySummaries.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getCompanyTypes.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getCompanyTypes.fulfilled, (state, action) => {
        state.companyTypes = action.payload
        state.isLoading = false
      })
      .addCase(getCompanyTypes.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getRelatedContacts.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getRelatedContacts.fulfilled, (state, action) => {
        state.relatedContacts = action.payload
        state.isLoading = false
      })
      .addCase(getRelatedContacts.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getRelatedCompanies.fulfilled, (state, action) => {
        state.relatedCompanies = action.payload
        state.isLoading = false
      })
      .addCase(addLinkedCompany.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(deleteLinkedCompany.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getXeroRef.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getXeroRef.fulfilled, (state, action) => {
        state.xeroRef = action.payload
        state.isLoading = false
      })
      .addCase(getXeroRef.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(addCompany.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addCompany.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(addCompany.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(searchCompany.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(searchCompany.fulfilled, (state, action) => {
        state.searchResults = action.payload
        state.isLoading = false
      })
      .addCase(searchCompany.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
  },
})

export {
  getCompany,
  editCompany,
  getCompanies,
  getCompanySummaries,
  addCompany,
  getCompanyTypes,
  getOpportunities,
  getContracts,
  getRelatedContacts,
  searchCompany,
}
export default companySlice.reducer
