import React from 'react'

interface StatusIndicatorProps {
  color: string
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({color}) => {
  let circleColor = ''

  switch (color) {
    case 'green':
      circleColor = '#28a745'
      break
    case 'purple':
      circleColor = '#6f42c1'
      break
    case 'red':
      circleColor = '#dc3545'
      break
    case 'yellow':
      circleColor = '#ffc107'
      break
    default:
      circleColor = '#000' // Default color if invalid color provided
  }

  return (
    <div
      style={{width: '12px', height: '12px', borderRadius: '50%', backgroundColor: circleColor}}
    ></div>
  )
}

export default StatusIndicator
