import {Route, Routes} from 'react-router-dom'
import {lazy, Suspense} from 'react'
import PageLoader from 'components/PageLoader'
import RoutePaths from 'enums/routes'

const ListContacts = lazy(() => import(/* webpackChunkName: "ListContacts" */ 'pages/contact'))

export default function ContactRoutes() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path={RoutePaths.HOME} element={<ListContacts />} />
        <Route path={RoutePaths.CREATE} element={<ListContacts />} />
        <Route path={RoutePaths.EDIT} element={<ListContacts />} />
      </Routes>
    </Suspense>
  )
}
