import {combineReducers} from 'redux'
import companyReducer from './reducers/company'
import contactReducer from './reducers/contact'
import serviceReducer from './reducers/service'
import siteReducer from './reducers/site'
import noteReducer from './reducers/note'
import authReducer from './reducers/auth'
import licenseReducer from './reducers/license'

const rootReducer = combineReducers({
  company: companyReducer,
  contact: contactReducer,
  service: serviceReducer,
  site: siteReducer,
  note: noteReducer,
  auth: authReducer,
  license: licenseReducer,
})

export default rootReducer
