export const SalesIssueStatus = {
  LEAD: 'lead',
  QUALIFIED_LEAD: 'qualified',
  PRESENTATION: 'presentation',
  PROPOSAL: 'pending',
  // PENDING_SITE_SURVEY: 'pending_site_survey',
  // SITE_SURVEY_BOOKED: 'site_survey_booked',
  // NEGOTIATE: 'negotiate',
}

export const SalesIssueStatusCopy = {
  [SalesIssueStatus.LEAD]: 'Lead',
  [SalesIssueStatus.QUALIFIED_LEAD]: 'Qualified Lead',
  [SalesIssueStatus.PRESENTATION]: 'Presentation',
  [SalesIssueStatus.PROPOSAL]: 'Proposal',
  // [IssueStatus.PENDING_SITE_SURVEY]: 'Pending Site Survey',
  // [IssueStatus.SITE_SURVEY_BOOKED]: 'Site Survey Booked',
  // [IssueStatus.NEGOTIATE]: 'negotiate',
}

export const SalesIssueStatusValue = {
  [SalesIssueStatus.LEAD]: '£200',
  [SalesIssueStatus.QUALIFIED_LEAD]: '£400',
  [SalesIssueStatus.PRESENTATION]: '£600',
  [SalesIssueStatus.PROPOSAL]: '£800',
  // [IssueStatus.PENDING_SITE_SURVEY]: '£1000',
  // [IssueStatus.SITE_SURVEY_BOOKED]: '£1200',
  // [IssueStatus.NEGOTIATE]: '£1400',
}

export const SalesIssueStatusColor = {
  [SalesIssueStatus.LEAD]: 'green',
  [SalesIssueStatus.QUALIFIED_LEAD]: 'purple',
  [SalesIssueStatus.PRESENTATION]: 'yellow',
  [SalesIssueStatus.PROPOSAL]: 'red',
  // [IssueStatus.PENDING_SITE_SURVEY]: 'yellow',
  // [IssueStatus.SITE_SURVEY_BOOKED]: 'yellow',
  // [IssueStatus.NEGOTIATE]: 'yellow',
}

export const IssueStatus = {
  PENDING_INSTALL: 'pending',
  INSTALL_BOOKED: 'booked',
  FAILED_INSTALL: 'failed',
  GRACE_PERIOD: 'grace',
}

export const IssueStatusCopy = {
  [IssueStatus.PENDING_INSTALL]: 'Pending Install',
  [IssueStatus.INSTALL_BOOKED]: 'Install Booked',
  [IssueStatus.FAILED_INSTALL]: 'Failed Install',
  [IssueStatus.GRACE_PERIOD]: 'Grace Period',
}

export const IssueStatusValue = {
  [IssueStatus.PENDING_INSTALL]: '£400',
  [IssueStatus.INSTALL_BOOKED]: '£600',
  [IssueStatus.FAILED_INSTALL]: '£800',
  [IssueStatus.GRACE_PERIOD]: '£1000',
}

export const IssueStatusColor = {
  [IssueStatus.PENDING_INSTALL]: 'green',
  [IssueStatus.INSTALL_BOOKED]: 'purple',
  [IssueStatus.FAILED_INSTALL]: 'red',
  [IssueStatus.GRACE_PERIOD]: 'yellow',
}

export const AccountingIssueStatus = {
  READY_INVOICE: 'booked',
  AWAITING_PAYMENT: 'awaiting_payment',
  PAYMENT_RECIEVED: 'payment_recieved',
  WON: 'won',
  LOST: 'lost',
  // PENDING_SITE_SURVEY: 'pending_site_survey',
  // SITE_SURVEY_BOOKED: 'site_survey_booked',
  // NEGOTIATE: 'negotiate',
}

export const AccountingIssueStatusCopy = {
  [AccountingIssueStatus.READY_INVOICE]: 'Ready to Invoice',
  [AccountingIssueStatus.AWAITING_PAYMENT]: 'Awaiting Payment',
  [AccountingIssueStatus.PAYMENT_RECIEVED]: 'Payment Recieved',
  [AccountingIssueStatus.WON]: 'Closed/Won',
  [AccountingIssueStatus.LOST]: 'Closed/Lost',
  // [IssueStatus.PENDING_SITE_SURVEY]: 'Pending Site Survey',
  // [IssueStatus.SITE_SURVEY_BOOKED]: 'Site Survey Booked',
  // [IssueStatus.NEGOTIATE]: 'negotiate',
}

export const AccountingIssueStatusValue = {
  [AccountingIssueStatus.READY_INVOICE]: '£200',
  [AccountingIssueStatus.AWAITING_PAYMENT]: '£400',
  [AccountingIssueStatus.PAYMENT_RECIEVED]: '£600',
  [AccountingIssueStatus.WON]: '£800',
  [AccountingIssueStatus.LOST]: '£1000',
  // [IssueStatus.SITE_SURVEY_BOOKED]: '£1200',
  // [IssueStatus.NEGOTIATE]: '£1400',
}

export const AccountingIssueStatusColor = {
  [AccountingIssueStatus.READY_INVOICE]: 'green',
  [AccountingIssueStatus.AWAITING_PAYMENT]: 'purple',
  [AccountingIssueStatus.PAYMENT_RECIEVED]: 'red',
  [AccountingIssueStatus.WON]: 'yellow',
  [AccountingIssueStatus.LOST]: 'grey',
  // [IssueStatus.PENDING_SITE_SURVEY]: 'yellow',
  // [IssueStatus.SITE_SURVEY_BOOKED]: 'yellow',
  // [IssueStatus.NEGOTIATE]: 'yellow',
}
