import {
  requestBase as request,
  IGetAsyncFunction,
  IPostAsyncFunction,
  IPutAsyncFunction,
  handleAxiosError,
} from '../requestBase'
import {IContact} from 'types/contact'

const getContact = async (token: string, contactId: string) => {
  try {
    const result = await request(token).get(`Contact/${contactId}`)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getContacts: IGetAsyncFunction = async (token) => {
  try {
    const result = await request(token).get('Contact')
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const addContact: IPostAsyncFunction = async (token: string, data?: IContact) => {
  try {
    const result = await request(token).post('Contact', data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const linkContact: IPostAsyncFunction = async (
  token: string,
  contactId?: string,
  category?: string,
  categoryId?: string
) => {
  try {
    const result = await request(token).post(`Contact/Link/${contactId}/${category}/${categoryId}`)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const editContact = async (token: string, data: IContact, id: string) => {
  try {
    const result = await request(token).put(`Contact/${id}`, data)
    return result.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const searchContacts: any = async (token: string, term: string) => {
  try {
    const result = await request(token).get(`Contact?search=${term}`)
    return result.data?.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const contactService = {
  getContact,
  getContacts,
  addContact,
  editContact,
  linkContact,
  searchContacts,
}

export default contactService
