import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Navbar, Nav} from 'react-bootstrap'
import {UserOutlined, VerifiedOutlined} from '@ant-design/icons'

interface SiderProps {
  isNavMenuClose: boolean
  onCollapse: () => void
}

const Sider: React.FC<SiderProps> = ({isNavMenuClose, onCollapse}) => {
  const location = useLocation()
  // const [expanded, setExpanded] = useState(true)
  const pathname = location.pathname

  // const handleToggleCollapse = () => {
  //   setExpanded(!expanded)
  //   onCollapse()
  // }

  return (
    <Navbar collapseOnSelect expand="lg" expanded={!isNavMenuClose} className="navigation">
      {/* <Navbar.Toggle onClick={handleToggleCollapse} aria-controls="responsive-navbar-nav" /> */}
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="flex-column">
          <Nav.Item>
            <Nav.Link as={Link} to="/admin/users" active={pathname === '/admin/users'}>
              <UserOutlined style={{marginRight: '3px'}} />
              Users
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to="/admin/services" active={pathname === '/admin/services'}>
              <VerifiedOutlined style={{marginRight: '3px'}} />
              Services
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to="/admin/kanban" active={pathname === '/admin/kanban'}>
              <VerifiedOutlined style={{marginRight: '3px'}} />
              Kanban
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Sider
