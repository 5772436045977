import {Alert} from 'react-bootstrap'

const ErroToast = ({title, errors}: {title: string; errors: any}) => {
  let errorMessage: any = ''
  if (errors) {
    for (const field in errors) {
      // eslint-disable-next-line no-prototype-builtins
      if (errors.hasOwnProperty(field)) {
        const fieldErrors = errors[field]
        if (fieldErrors) errorMessage += `\n${fieldErrors.join(', ')}`
      }
    }
  }

  return (
    <Alert variant="error">
      <Alert.Heading style={{fontSize: '16px', fontWeight: '800'}}>{title}</Alert.Heading>
      <p style={{fontSize: '14px'}}>{errorMessage}</p>
    </Alert>
  )
}

export default ErroToast
