import CustomPagination from 'components/CustomPagination'
import {StyledDataTable} from 'styles/styled'
import {ICompany, ICompanyColumnDef} from 'types/company'
import {IContact, IContactColumnDef} from 'types/contact'

interface IDataTable {
  data: ICompany[] | IContact[] | any[]
  columns: ICompanyColumnDef[] | IContactColumnDef | any[]
  responsive?: boolean
  onRowClicked?: (args: any) => void
}

const customStyles = {
  rows: {
    style: {
      cursor: 'pointer',
    },
  },
}

const DataTable = (props: IDataTable) => {
  const {data, columns, responsive = true, onRowClicked} = props

  return (
    <StyledDataTable
      columns={columns}
      data={data}
      paginationComponent={CustomPagination}
      responsive={responsive}
      onRowClicked={(data: any) => onRowClicked?.(data)}
      customStyles={customStyles}
      pagination
      highlightOnHover
    />
  )
}

export default DataTable
