// src/reducers/siteSlice.js
import {createSlice} from '@reduxjs/toolkit'
import {getSite, getSites, addSite, editSite, deleteSite} from '../actions/siteActions'

const initialState = {
  site: {},
  sites: [],
  links: [],
  isLoading: false,
  isError: false,
}

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSite.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getSite.fulfilled, (state, action) => {
        state.site = action.payload?.data
        state.links = action.payload?.links
        state.isLoading = false
      })
      .addCase(getSite.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(getSites.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(getSites.fulfilled, (state, action) => {
        state.sites = action.payload
        state.isLoading = false
      })
      .addCase(getSites.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(addSite.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addSite.fulfilled, (state, action) => {
        // Handle the fulfilled state if needed
        state.isLoading = false
      })
      .addCase(addSite.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(editSite.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(editSite.fulfilled, (state, action) => {
        // Handle the fulfilled state if needed
        state.isLoading = false
      })
      .addCase(editSite.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(deleteSite.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(deleteSite.fulfilled, (state, action) => {
        // Handle the fulfilled state if needed
        state.isLoading = false
      })
      .addCase(deleteSite.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
  },
})

export {getSite, getSites, addSite, editSite, deleteSite}
export default siteSlice.reducer
