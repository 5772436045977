// src/reducers/serviceSlice.js
import {createSlice} from '@reduxjs/toolkit'
import {addressSearch, addressGet} from '../actions/serviceActions'

const initialState = {
  addressSearchResults: [],
  addressGetResults: {},
  isLoading: false,
  isError: false,
}

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addressSearch.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addressSearch.fulfilled, (state, action) => {
        state.addressSearchResults = action.payload
        state.isLoading = false
      })
      .addCase(addressSearch.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
      .addCase(addressGet.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(addressGet.fulfilled, (state, action) => {
        state.addressGetResults = action.payload
        state.isLoading = false
      })
      .addCase(addressGet.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
  },
})

export {addressSearch, addressGet}
export default serviceSlice.reducer
