import Image from 'react-bootstrap/Image'
import {styled} from 'styled-components'

interface IAvatar {
  firstName: string
  lastName: string
  imageUrl?: string
  title?: string
  email?: string
}

const StyledAvatarImage = styled(Image)`
  width: 40px;
  height: 40px;
`

const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  background-color: #2d3648;
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
`

const Avatar = (props: IAvatar) => {
  const {firstName, lastName, imageUrl, title, email} = props
  const hasImage = imageUrl && imageUrl.trim() !== ''
  const initials = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`

  return (
    <div className="d-flex align-items-center">
      {hasImage ? (
        <StyledAvatarImage src={imageUrl} roundedCircle alt={initials} className="mr-2" />
      ) : (
        <>
          <StyledAvatar className="d-flex justify-content-center align-items-center">
            {initials}
          </StyledAvatar>
          <div className="d-flex flex-column mb-1">
            {title && <span className="ms-2  strong">{title}</span>}
            {email && <small className="ms-2 strong">{email}</small>}
          </div>
        </>
      )}
    </div>
  )
}

export default Avatar
