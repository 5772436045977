import {requestBase as request, IPostAsyncFunction, handleAxiosError} from '../requestBase'

const getServiceType = async (token: string) => {
  try {
    const result = await request(token).get(`License/Service-Type`)
    return result.data.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const addServiceType: IPostAsyncFunction = async (token: string, data?: any) => {
  try {
    const result = await request(token).post(`License/Service-Type`, data)
    return result.data.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const deleteServiceType: any = async (token: string, serviceTypeId: any) => {
  try {
    const result = await request(token).delete(`License/Service-Type/${serviceTypeId}`)
    return result.data.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const addLicense: IPostAsyncFunction = async (token: string, data?: any) => {
  try {
    const result = await request(token).post('License', data)
    return result.data.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getLicense = async (token: string, id: any) => {
  try {
    const result = await request(token).get(`License/${id}`)
    return result.data.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const getAllLicenses = async (token: string) => {
  try {
    const result = await request(token).get(`License`)
    return result.data.data
  } catch (err) {
    handleAxiosError(err)
  }
}

const licenseService = {
  getServiceType,
  addServiceType,
  deleteServiceType,
  addLicense,
  getLicense,
  getAllLicenses,
}

export default licenseService
