import {useState} from 'react'
import {Card, Badge, ListGroup} from 'react-bootstrap'
import {ArrowUpOutlined, ArrowDownOutlined} from '@ant-design/icons'
import StatusIndicator from './StatusIndicator'
import {styled} from 'styled-components'
import {BaseColors} from 'enums/colors'
import {StyledIconWrapper} from 'styles/styled'

interface CollapsibleCardProps {
  title: string
  count?: number
  isArrow: boolean
  value?: string
  issue?: any
  indicatorColor?: string
}

const CollapsibleCard = ({
  title,
  count,
  isArrow,
  value,
  issue,
  indicatorColor = 'grey',
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = useState(!isArrow)

  const toggleCollapse = () => {
    if (isArrow) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <StyledCard className="mb-4">
      <CardHeaderStyled className="d-flex justify-content-between align-items-center p-4">
        <div className="d-flex justify-content-between align-items-center gap-2">
          {!isArrow && <StatusIndicator color={indicatorColor} />}
          <StyledCardTitle>{title}</StyledCardTitle>
        </div>
        {!isArrow && <span>({count})</span>}
        {isArrow && (
          <StyledIconWrapper onClick={toggleCollapse}>
            {isOpen ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          </StyledIconWrapper>
        )}
      </CardHeaderStyled>
      <CardBodyStyled isOpen={isOpen} isArrow={isArrow}>
        {!isArrow
          ? value
          : isOpen && (
              <ListGroup variant="flush" className="kanban-list-group">
                <ListGroup.Item>{issue.company}</ListGroup.Item>
                <ListGroup.Item>
                  <h4>
                    <Badge bg="primary">{issue.tags}</Badge>
                  </h4>
                </ListGroup.Item>
                <ListGroup.Item>Value: £{issue.value}</ListGroup.Item>
                <ListGroup.Item>Due Date: 2024-05-01</ListGroup.Item>
                <ListGroup.Item>Lead Owner: {issue.ownerDisplayName}</ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <StatusIndicator color={IssueStatusColor[issue.priority]} />
                    {issue.priority}
                  </div>
                </ListGroup.Item>
              </ListGroup>
            )}
      </CardBodyStyled>
    </StyledCard>
  )
}

export const IssuePriority = {
  HIGH: 'High',
  LOW: 'Low',
  MEDIUM: 'Medium',
}

export const IssueStatusColor = {
  [IssuePriority.HIGH]: 'red',
  [IssuePriority.LOW]: 'green',
  [IssuePriority.MEDIUM]: 'yellow',
}

const StyledCard = styled(Card)`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
`

const StyledCardTitle = styled.span`
  color: #2d3648;
  font-size: 16px;
  font-weight: bold;
`

const CardHeaderStyled = styled(Card.Header)`
  border: none;
  background: ${BaseColors.BASE_WHITE};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const CardBodyStyled = styled(Card.Body)<{isOpen: boolean; isArrow: boolean}>`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
  background-color: ${({isArrow}) => (isArrow ? BaseColors.BASE_WHITE : BaseColors.BASE300)};
`

export default CollapsibleCard
