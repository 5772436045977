import {Draggable} from 'react-beautiful-dnd'
import CollapsibleCard from 'components/CollapsibleCard'
import {IssueWrapper} from 'styles/styled'

const BoardListIssue = ({issue, index}: any) => {
  return (
    <Draggable draggableId={issue.id.toString()} index={index}>
      {(provided: any, snapshot) => {
        const isBeingdragged = snapshot.isDragging && !snapshot.isDropAnimating

        return (
          <IssueWrapper
            key={issue?.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isBeingdragged={isBeingdragged}
          >
            <CollapsibleCard title={issue.title} isArrow={true} issue={issue} />
          </IssueWrapper>
        )
      }}
    </Draggable>
  )
}

export default BoardListIssue
