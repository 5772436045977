export enum BaseColors {
  BASE900 = '#1A202C',
  BASE800 = '#2D3648',
  BASE700 = '#4A5468',
  BASE600 = '#717D96',
  BASE500 = '#A0ABC0',
  BASE400 = '#CBD2E0',
  BASE300 = '#E2E7F0',
  BASE200 = '#EDF0F7',
  BASE100 = '#F7F9FC',
  BASE_WHITE = '#FFFFFF',
}

export enum AccentColors {
  YELLOW = '#FFD43A',
  ORANGE = '#FE9D35',
  GREEN = '#2EDB4B',
  BLUE = '#34AFF7',
  RED = '#FF5252',
  PINK = '#F101D9',
  PURPLE = '#4A3AFF',
  BLUE400 = '#2D5BFF',
  MAGENTA = '#962DFF',
  TEAL = '#00E8B0',
}

export type BaseColor = keyof typeof BaseColors
export type AccentColor = keyof typeof AccentColors
