import {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import {StyledPageTitle} from 'styles/styled'
import ReusableModal from 'components/ReusableModal'
import useAuthentication from 'hooks/useAuthentication'
import {useDispatch} from 'react-redux'
import {getAllLicenses} from 'reduxStore/actions/licenseActions'
import {Link, Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {Navbar, Nav} from 'react-bootstrap'
import Kanban from './kanban'
import SalesTable from './table'

export default function Sales() {
  // const [searchText, setSearchText] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const [isEdit, setIsEdit] = useState(false)
  // const [editData, setEditData] = useState<any>({})
  const {token} = useAuthentication()

  const dispatch = useDispatch()
  // const licensesData = useSelector(selectAllLicenseData)

  const location = useLocation()
  const pathname = location.pathname

  useEffect(() => {
    if (token) {
      dispatch(getAllLicenses({token}))
    }
  }, [dispatch, token])

  const handleClose = () => {
    setIsModalOpen(false)
    // setIsEdit(false)
  }

  return (
    <>
      <Container>
        <Row className="align-items-center justify-content-between p-4">
          <Col md={6}>
            <StyledPageTitle className="text-left">Sales Pipeline</StyledPageTitle>
          </Col>
          <Col md={6} className="text-end">
            <Button
              variant="primary"
              onClick={() => {
                // setEditData(false)
                setIsModalOpen(true)
              }}
            >
              New
            </Button>
          </Col>
        </Row>
        <Row className="align-items-top justify-content-left p-0">
          <Col md={12} style={{marginLeft: '1rem'}}>
            <Navbar expand="sm" className="companies-nav p-0">
              <Container>
                <Navbar.Collapse className="justify-content-start" id="kanban-navigation">
                  <Nav activeKey={pathname}>
                    <Nav.Link
                      as={Link}
                      to="/sales/table"
                      active={pathname === '/sales/table'}
                      hidden={false}
                    >
                      Table
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/sales/kanban"
                      active={pathname === '/sales/kanban'}
                      hidden={false}
                    >
                      Kanban
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
          <Container
            style={{
              background: '#EDF0F7',
              padding: '42px 20px',
              height: '100vh',
              width: '100vw',
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
            }}
            fluid
          >
            <Routes>
              <Route path="/" element={<Navigate to="kanban" />} />
              <Route path="table" element={<SalesTable />} />
              <Route path="kanban" element={<Kanban />} />
            </Routes>
          </Container>
        </Row>
      </Container>

      <ReusableModal
        showModal={isModalOpen}
        handleClose={handleClose}
        modalTitle={'Add New issue'}
        showFooter={false}
        modalBody={<div>Issue Form</div>}
      />
    </>
  )
}
