import Container from 'react-bootstrap/Container'
import Stack from 'react-bootstrap/Stack'
import {translate} from 'i18n'
import {StyledPageTitle} from 'styles/styled'

export default function Dashboard() {
  return (
    <Container className="p-5 bg-light">
      <Stack gap={4}>
        <StyledPageTitle className="text-left">{translate('dashboard.title')}</StyledPageTitle>
      </Stack>
    </Container>
  )
}
