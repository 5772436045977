import {useState} from 'react'
import {translate} from 'i18n'
import {Row, Col, Stack, Button, Form, ListGroup, Badge} from 'react-bootstrap'
import {StyledTitle} from 'styles/styled'
import ReusableModal from 'components/ReusableModal'
import {serviceColumnsDef} from 'grids/adminColumnsDef'
import DataTable from 'react-data-table-component'
import AdminKanbanForm from 'forms/AdminKanbanForm'

export default function Kanban(props: any) {
  const [boards, setBoards]: any = useState([])
  const [newBoardName, setNewBoardName] = useState('')
  const [newColumnName, setNewColumnName] = useState('')
  const [columns, setColumns]: any = useState([])
  const [isAddKanbanModalOpen, setIsAddKanbanModalOpen] = useState(false)
  const [renderPropData, setRenderPropData]: any = useState([])
  console.log('renderPropData', renderPropData)

  const handleAddBoard = () => {
    const newBoard = {
      name: newBoardName,
      columns: [...columns],
    }
    setBoards([...boards, newBoard])
    setNewBoardName('')
    setColumns([])
  }

  const handleAddColumn = () => {
    if (newColumnName.trim() !== '') {
      setColumns([...columns, newColumnName])
      setNewColumnName('')
    }
  }

  const handleClose = () => {
    setIsAddKanbanModalOpen(false)
  }

  return (
    <>
      <Row className="align-items-center justify-content-between">
        <Col md={6}>
          <Stack>
            <StyledTitle>{translate('admin.kanban')}</StyledTitle>
          </Stack>
        </Col>

        <Col md={6} className="text-end">
          <Button variant="primary" onClick={() => setIsAddKanbanModalOpen(true)}>
            {translate('admin.addKanbanBoard')}
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <DataTable data={renderPropData} columns={kanbanColumnsDef} responsive />
        </Col>
      </Row>

      <ReusableModal
        showModal={isAddKanbanModalOpen}
        handleClose={handleClose}
        modalTitle={translate('admin.addKanbanBoard')}
        showFooter={false}
        modalBody={
          <AdminKanbanForm handleClose={handleClose} setRenderPropData={setRenderPropData} />
        }
      />
    </>
  )
}

export const kanbanColumnsDef: any = [
  {
    name: 'Kanban',
    selector: (row: any) => row.boardName,
    sortable: true,
  },
  {
    name: 'Type',
    selector: (row: any) => row.type,
    sortable: true,
  },
  {
    name: 'Columns',
    cell: (row: any) => <KanbanColumnWrapper row={row} />,
    sortable: true,
  },
]

export const KanbanColumnWrapper = ({row}: any) => (
  <div
    style={{
      padding: '10px',
    }}
  >
    {row?.columns?.map((column: any) => (
      <>
        <h6>
          <Badge key={column} bg="secondary" className="me-1">
            {column}
          </Badge>
        </h6>
      </>
    ))}
  </div>
)
